import {
  FunTeamBiddingGame,
  FunTeamBiddingActiveQuestion,
  FunTeamBiddingItem,
  FunTeamBiddingPlayer,
  FunTeamBiddingSettings,
  FunTeamBiddingCategory
} from '@/interfaces/funteambidding';
import { mixed } from '@/constants/avatars';

export const FUN_TEAM_BIDDING_PHASES: any = {
  SELECTING_CATEGORY: {
    value: 'SELECTING_CATEGORY',
    step: 1
  },
  BIDDING: {
    value: 'BIDDING',
    step: 2
  },
  ANSWER: {
    value: 'ANSWER',
    step: 3
  }
};

export const createTeam = (id: string, name: string, money: number, avatar: string) => ({
  id,
  players: [],
  money,
  name,
  avatar,
  loan: 0
});

export const openTriviaDatabaseCategories: FunTeamBiddingCategory[] = [
  { id: 'GENERAL_KNOWLEDGE', label: 'general' },
  { id: 'FILM', label: 'film' },
  { id: 'MUSIC', label: 'music' },
  { id: 'SCIENCE_NATURE', label: 'nature' },
  { id: 'SCIENCE_COMPUTERS', label: 'computers' },
  { id: 'MATHEMATICS', label: 'mathematics' },
  { id: 'MYTHOLOGY', label: 'mythology' },
  { id: 'SPORTS', label: 'sports' },
  { id: 'GEOGRAPHY', label: 'geography' },
  { id: 'HISTORY', label: 'history' },
  { id: 'POLITICS', label: 'politics' },
  { id: 'ANIMALS', label: 'animals' },
  { id: 'CUSTOM', label: 'custom' }
];

export const emptyFunTeamBiddingSettings: FunTeamBiddingSettings = {
  categories: openTriviaDatabaseCategories,
  teamMoneyForStart: 10000,
  moneyTakenForStartOfBidding: 400,
  timeAfterBidInSeconds: 20,
  maxDebt: 25000
};

export const emptyFunTeamBiddingGame: FunTeamBiddingGame = {
  id: '',
  createdOn: 0,
  answeringTeamName: '',
  settings: emptyFunTeamBiddingSettings,
  gameStarted: false,
  gameEnded: false,
  gameCancelled: false,
  endResults: {},
  teams: [
    createTeam('1', 'Team 1', 1000, mixed[0]),
    createTeam('2', 'Team 2', 1000, mixed[1]),
    createTeam('3', 'Team 3', 1000, mixed[2]),
    createTeam('4', 'Team 4', 1000, mixed[3])
  ],
  players: [],
  customQuestions: [],
  phase: 'WELCOME',
  bidding: [],
  activeCategory: '',
  categories: openTriviaDatabaseCategories,
  activeQuestion: {
    id: '',
    type: '',
    textPl: '',
    textEn: '',
    options: ['', '', '', ''],
    validAnswer: ''
  },
  answerValid: false,
  answered: '',
  jackpot: 0,
  endOfBidding: null,
  noOfQuestionsLeft: 15,
  avatarsPack: 'mixed',
  teamsPassedFromBidding: [],
  vaBanqueTeam: null,
  gameCreator: null
};

export const emptyFunTeamBiddingItem: FunTeamBiddingItem = {
  teamName: '',
  value: 0,
  teamAvatar: ''
};

export const emptyFunTeamBiddingActiveQuestion: FunTeamBiddingActiveQuestion = {
  id: '',
  textPl: '',
  textEn: '',
  type: 'multiple',
  options: ['', '', '', ''],
  validAnswer: ''
};

export const emptyFunTeamBiddingPlayer: FunTeamBiddingPlayer = {
  isCaptain: false,
  id: '',
  name: '',
  nickname: '',
  email: '',
  strength: '',
  weakness: '',
  team: '',
  admin: false
};

// giuthub trivia questions

// https://opentdb.com/api_config.php
// http://jservice.io/ słabe
// https://fungenerators.com/api/trivia/#ratelimit - hajs
// https://quizapi.io/docs/1.0/authentication - only technical questions
// https://rapidapi.com/twinword/api/word-quiz
// https://rapidapi.com/webknox/api/trivia-knowledge-facts
// https://rapidapi.com/roomtek/api/music-trivia
// https://rapidapi.com/orthosie/api/trivia5
// https://rapidapi.com/apininjas/api/trivia-by-api-ninjas
// multiple choice / true / false
