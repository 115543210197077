
  import { computed, defineComponent, onMounted, ref } from 'vue';

  import useFirebase from '@/composables/use-firebase';
  import CompanyLogo from '@/components/common/CompanyLogo.vue';
  import RemoteFunLogo from '@/components/common/RemoteFunLogo.vue';
  import LogoSpinner from '@/components/common/LogoSpinner.vue';
  import { useStore } from 'vuex';
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'App',
    components: { CompanyLogo, RemoteFunLogo, LogoSpinner },
    setup() {
      const { getCurrentUser, error } = useFirebase();
      const store = useStore();
      const { push, currentRoute } = useRouter();
      const loading = ref(true);

      const user = getCurrentUser();

      onMounted(async () => {
        // await store.dispatch('sendAdminEmail', {
        //   @ts-ignore
        // message: `Sb went to App.vue - ${user}. Route name - ${currentRoute.value.name}`
        // });

        const blogRoutes = [
          'blog-5-lessons',
          'blog-5-wskazowek',
          'blog-remote-fun-customizable',
          'blog-remote-fun-zdalny'
        ];

        // @ts-ignore
        if (blogRoutes.includes(currentRoute.value.name)) {
          loading.value = false;
          return;
        }

        try {
          await store.dispatch('checkTimeOffset', Date.now());
        } catch {
          await store.dispatch('sendAdminEmail', {
            message: 'ERROR: Check time offset errored'
          });
        }

        firebase.auth().onAuthStateChanged(async _user => {
          const noAuthRoutes = [
            'register',
            'forgot-password',
            'checkout-with-register',
            'checkout-without-register',
            'free-checkout',
            'play-without-register',
            'checkout-success',
            'checkout-fail',
            'blog-5-lessons',
            'blog-5-wskazowek',
            'blog-remote-fun-customizable',
            'blog-remote-fun-zdalny',
            'funicebreaker-new-game-without-register',
            'funteambidding-new-game-without-register',
            'funteamclues-new-game-without-register',
            'funbusinessgame-new-game-without-register'
          ];

          if (_user) {
            store.commit('setUser', {
              email: _user?.email,
              id: _user?.uid
            });
          }

          // @ts-ignore
          if (noAuthRoutes.includes(currentRoute.value.name)) {
            loading.value = false;
            return;
          }

          const gamesRoutes = [
            'fundice-game',
            'fundice-new-game',
            'funicebreaker-game',
            'funicebreaker-new-game-without-register',
            'funicebreaker-new-game',
            'funteambidding-game',
            'funteambidding-new-game-without-register',
            'funteambidding-new-game',
            'funteamclues-game',
            'funteamclues-new-game-without-register',
            'funteamclues-new-game',
            'funbusinessgame-game',
            'funbusinessgame-new-game-without-register',
            'funbusinessgame-new-game'
          ];

          // @ts-ignore
          const isGame = gamesRoutes.includes(currentRoute.value.name);

          if (!_user && isGame) {
            await firebase
              .auth()
              .signInAnonymously()
              .then(
                () => {},
                () => {}
              )
              .catch(() => {})
              .finally(() => {
                loading.value = false;
              });
          }

          if (!_user?.email && !isGame && currentRoute.value.name) {
            push({ name: 'login' });
          }

          loading.value = false;
        });
      });

      return {
        user,
        loading,
        error: computed(() => error)
      };
    }
  });
