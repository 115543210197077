<template>
  <div v-if="logo" class="company-logo">
    <img :src="logo" />
  </div>
</template>

<script>
  export default {
    name: 'CompanyLogo',
    computed: {
      logo() {
        return this.$store.state.company.logo;
      }
    }
  };
</script>

<style scoped lang="scss">
  .company-logo {
    position: fixed;
    top: global-vars(ui-default-measure);
    left: global-vars(ui-default-measure);

    @include mobile() {
      display: none;
    }

    img {
      border-radius: 4px;
      padding: 10px;
      background: white;
      height: auto;
      max-width: 160px;
      max-height: 80px;
    }
  }
</style>
