<template>
  <div class="remotefun-logo">
    <img :src="require(`@/assets/logos/remote-fun/logo-full-white.svg`)" alt="RemoteFun logo" />
  </div>
</template>

<script>
  export default {
    name: 'RemoteFunLogo',
    computed: {
      logo() {
        return this.$store.state.company.logo;
      }
    }
  };
</script>

<style scoped lang="scss">
  .remotefun-logo {
    position: fixed;
    top: global-vars(ui-default-measure2x);
    left: global-vars(ui-default-measure2x);

    @include mobile() {
      display: none;
    }

    img {
      height: auto;
      width: 90px;

      @include mobile() {
        width: 60px;
      }
    }
  }
</style>
