import { toRefs, reactive, ref } from 'vue';
import firebase from 'firebase';
import 'firebase/firestore';

import { AuthState } from '@/interfaces/businessgame';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import useToast from '@/composables/use-toast';
import { GAME_NAMES } from '@/helpers';
import { useI18n } from 'vue-i18n';

if (firebase.apps.length === 0) {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  };
  firebase.initializeApp(firebaseConfig);
}

export default function () {
  const { push } = useRouter();
  const store = useStore();
  const { t } = useI18n();

  let state: AuthState = reactive({
    error: '',
    user: null
  });

  const currentCompany: any = ref({});
  const currentGame: any = ref(null);
  const { successToast, errorToast } = useToast();

  const db = firebase.firestore();

  const getCurrentCompany = async (companyId: string | string[] | null = null) => {
    const user = firebase.auth().currentUser;
    if (!user) {
      return;
    }

    let company;

    if (companyId) {
      company = await db
        .doc(`companies/${companyId}`)
        .get()
        .then(doc => {
          return { id: doc.id, ...doc.data() };
        });
    } else {
      company = await db
        .collection('companies')
        .where('userEmails', 'array-contains', user?.email)
        .get()
        .then((querySnapshot: any) => {
          const resultArray: any = [];

          querySnapshot.forEach((doc: any) => {
            resultArray.push({ id: doc.id, ...doc.data() });
          });

          return resultArray[0];
        });
    }

    currentCompany.value = company;

    document.documentElement.style.setProperty('--color-accent', `${company.brandColor}`);

    return company;
  };

  const createNewGame = async (documentData: any): Promise<string> => {
    delete documentData.id;

    return await db
      .collection(`companies/${currentCompany.value.id}/games`)
      .add({
        ...documentData,
        createdOn: Date.now()
      })
      .then(docRef => {
        return docRef.get();
      })
      .then(doc => {
        return doc.id;
      });
  };

  const removeOneGameFromPackage = async () => {
    await db.doc(`companies/${store.state.company.id}`).update({
      package: {
        ...store.state.company.package,
        gamesLeft: {
          ...store.state.company.package.gamesLeft,
          [GAME_NAMES.FUN_BUSINESS_GAME]:
            store.state.company.package.gamesLeft[GAME_NAMES.FUN_BUSINESS_GAME] - 1
        }
      }
    });
  };

  const getCurrentGame = async (companyId: string | undefined, gameId: string | undefined) => {
    const company = await getCurrentCompany(companyId);
    return await db
      .doc(`companies/${company.id}/games/${gameId}`)
      .get()
      .then(doc => {
        return { id: doc.id, ...doc.data() };
      });
  };

  const getCurrentUser = () => {
    return firebase.auth().currentUser;
  };

  const logout = async () => {
    try {
      await firebase.auth().signOut();
      successToast(t('common.logout'));
      push('/login');
      store.commit('clearStore');
    } catch (e) {
      errorToast(e);
    }
  };

  const addAlertThatNewGameStarted = async (
    gameName: string,
    companyId: string,
    companyName: string,
    gameId: string = ''
  ) => {
    return await db.collection('gamesStartedLogs').add({
      // add info about the game
      // what game, which account, what company
      companyId,
      companyName,
      gameId,
      playerEmail: firebase.auth().currentUser?.email || null,
      gameName,
      createdOn: Date.now()
    });
  };

  const sendFeedback = async (
    rating: number,
    feedback: string,
    gameId: string,
    companyId: string,
    companyName: string,
    userEmail: string | undefined,
    isWrittenFromAdminPanel: boolean = false
  ) => {
    return await db
      .collection(isWrittenFromAdminPanel ? 'feedbackFromAdminPanel' : 'feedback')
      .add({
        rating,
        companyId,
        companyName,
        gameId,
        feedback,
        userEmail: userEmail || null,
        createdOn: Date.now()
      });
  };

  return {
    getCurrentCompany,
    createNewGame,
    getCurrentUser,
    getCurrentGame,
    currentCompany,
    currentGame,
    logout,
    sendFeedback,
    removeOneGameFromPackage,
    addAlertThatNewGameStarted,
    ...toRefs(state)
  };
}
