import { createStore } from 'vuex';
import axios from 'axios';
import { Card, ChanceCard } from '@/interfaces/businessgame';
import { emptyFunTeamBiddingGame } from '@/helpers/funteambidding/initials';
import { AvatarObject, defaultAvatars } from '@/constants/avatars';
import { emptyFunIceBreakerGame } from '@/helpers/funicebreaker/initials';
import {
  FunTeamBiddingActiveQuestion,
  FunTeamBiddingCategory,
  FunTeamBiddingGame,
  FunTeamBiddingSettings
} from '@/interfaces/funteambidding';
import { FunIceBreakerGame, FunIceBreakerSettings } from '@/interfaces/funicebreaker';
import { FunTeamCluesGame, FunTeamCluesSettings } from '@/interfaces/funteamclues';
import { emptyFunTeamCluesGame } from '@/helpers/funteamclues/initials';

interface Company {
  id: null;
  brandColor: string;
  logo: string;
  name: string;
  settings: any;
  package: any;
  avatars: AvatarObject;
  userEmails: any[];
  chanceCards: any[];
  boardConfig: any[];
  customBoardConfigTemplates: Card[];
  customChanceCardsTemplates: ChanceCard[];
  upgradeConfig: {};
  referralData: {
    balance: number;
    referredTo: any[];
    historyOfUsedBalance: any[];
  };
  historyOfPurchases: any[];
  funTeamBiddingSettings: FunTeamBiddingSettings;
  funIceBreakerSettings: FunIceBreakerSettings;
  funTeamCluesSettings: FunTeamCluesSettings;
  funIceBreakerCustomQuestions: any[];
  funTeamBiddingCustomQuestions: any[];
  funTeamBiddingPresets: {
    name: string;
    question: FunTeamBiddingActiveQuestion[];
  }[];
  signupLanguage: string;
}

interface InitialState {
  user: any;
  company: Company;
  users: any[];
  games: any[];
  funDiceGames: any[];
  funTeamBiddingGames: FunTeamBiddingGame[];
  funIceBreakerGames: FunIceBreakerGame[];
  funTeamCluesGames: FunTeamCluesGame[];
  currentFunTeamBiddingGame: FunTeamBiddingGame;
  currentFunIceBreakerGame: FunIceBreakerGame;
  currentFunTeamCluesGame: FunTeamCluesGame;
  soundOn: boolean;
  allCompanies: Company[];
  timeOffset: number;
}

const initialState: InitialState = {
  user: null,
  company: {
    id: null,
    brandColor: '',
    logo: '',
    name: '',
    settings: null,
    package: null,
    avatars: { ...defaultAvatars },
    userEmails: [],
    chanceCards: [],
    boardConfig: [],
    customBoardConfigTemplates: [],
    customChanceCardsTemplates: [],
    upgradeConfig: {},
    referralData: {
      balance: 0,
      referredTo: [],
      historyOfUsedBalance: []
    },
    historyOfPurchases: [],
    funTeamBiddingSettings: {
      categories: [],
      teamMoneyForStart: 0,
      moneyTakenForStartOfBidding: 0,
      timeAfterBidInSeconds: 0,
      maxDebt: 0
    },
    funIceBreakerSettings: {
      defaultNoOfQuestions: 0
    },
    funTeamCluesSettings: {
      timeForGuessingInSeconds: 0,
      timeForGivingClueInSeconds: 0
    },
    funIceBreakerCustomQuestions: [],
    funTeamBiddingCustomQuestions: [],
    funTeamBiddingPresets: [],
    signupLanguage: 'en'
  },
  users: [],
  games: [],
  funDiceGames: [],
  funTeamBiddingGames: [],
  funIceBreakerGames: [],
  funTeamCluesGames: [],
  currentFunTeamBiddingGame: {
    ...emptyFunTeamBiddingGame
  },
  currentFunIceBreakerGame: {
    ...emptyFunIceBreakerGame
  },
  currentFunTeamCluesGame: {
    ...emptyFunTeamCluesGame
  },
  soundOn: false,
  allCompanies: [],
  timeOffset: 0
};

export const store = createStore({
  state() {
    return { ...initialState };
  },
  mutations: {
    setUser(state: any, user: any) {
      state.user = {
        ...state.user,
        ...user
      };
    },
    setCurrentUserRoles(state: any, roles: any) {
      state.user = {
        ...state.user,
        roles: roles
      };
    },
    setCompany(state: any, company: any) {
      state.company = company;
    },
    setCompanyUsers(state: any, users: any) {
      state.users = users;
    },
    setCompanyGames(
      state: any,
      { games, funDiceGames, funIceBreakerGames, funTeamBiddingGames, funTeamCluesGames }
    ) {
      state.games = games;
      state.funDiceGames = funDiceGames;
      state.funIceBreakerGames = funIceBreakerGames;
      state.funTeamBiddingGames = funTeamBiddingGames;
      state.funTeamCluesGames = funTeamCluesGames;
    },
    setBrandingColor(state: any, color: string) {
      state.company.brandColor = color;
    },
    updateLogo(state: any, logo: string) {
      state.company.logo = logo;
    },
    addUserToPendingInvites(state, user) {
      state.users = [...state.users, user];
      state.company.userEmails = [...state.company.userEmails, user.email];
    },
    setUserRoles(state, { id, roles }) {
      const index = state.users.findIndex((user: any) => user.id === id);

      state.users = [
        ...state.users.slice(0, index),
        { ...state.users[index], roles },
        ...state.users.slice(index + 1)
      ];
    },
    handleCheckoutSuccess(state, payload) {
      state.company = {
        ...state.company,
        ...payload
      };
    },
    setChanceCard(state, { id, method, payload }) {
      const index = state.company.chanceCards.findIndex((card: ChanceCard) => card.id === id);

      state.company.chanceCards = [
        ...state.company.chanceCards.slice(0, index),
        {
          ...state.company.chanceCards[index],
          method,
          payload
        },
        ...state.company.chanceCards.slice(index + 1)
      ];
    },
    addNewChanceCard(state, payload) {
      state.company.chanceCards = [payload, ...state.company.chanceCards];
    },
    setChanceCards(state, payload) {
      state.company.chanceCards = payload;
    },
    setBoardConfig(state, payload) {
      state.company.boardConfig = payload;
    },
    setCardUpgradeConfig(state, payload) {
      state.company.upgradeConfig = payload;
    },
    addToCustomBoardConfigTemplates(state, payload) {
      state.company.customBoardConfigTemplates = [
        ...state.company.customBoardConfigTemplates,
        payload
      ];
    },
    addToCustomChanceCardsTemplates(state, payload) {
      state.company.customChanceCardsTemplates = [
        ...state.company.customChanceCardsTemplates,
        payload
      ];
    },
    addToCustomTeamBiddingPresets(state, payload) {
      state.company.funTeamBiddingPresets = [...state.company.funTeamBiddingPresets, payload];
    },
    clearStore(state) {
      state.user = null;
      state.company = { ...initialState.company };
      state.users = [];
      state.games = [];
    },
    setAvatarsPack(state, newAvatarsPack) {
      state.company.avatars = newAvatarsPack;
    },
    saveFunTeamBiddingSettings(state, payload) {
      state.company.funTeamBiddingSettings = payload;
    },
    saveFunIceBreakerSettings(state, payload) {
      state.company.funIceBreakerSettings = payload;
    },
    saveFunTeamCluesSettings(state, payload) {
      state.company.funTeamCluesSettings = payload;
    },
    setCurrentFunTeamBiddingGame(state, payload) {
      state.currentFunTeamBiddingGame = payload;
    },
    setCurrentFunTeamCluesGame(state, payload) {
      state.currentFunTeamCluesGame = payload;
    },
    setCurrentFunIceBreakerGame(state, payload) {
      state.currentFunIceBreakerGame = payload;
    },
    removeFromFunTeamBiddingCategories(state, payload) {
      state.company.funTeamBiddingSettings = {
        ...state.company.funTeamBiddingSettings,
        categories: state.company.funTeamBiddingSettings.categories.filter(
          (c: FunTeamBiddingCategory) => c.id !== payload.id
        )
      };
    },
    addToFunTeamBiddingCategories(state, payload) {
      state.company.funTeamBiddingSettings = {
        ...state.company.funTeamBiddingSettings,
        categories: [...state.company.funTeamBiddingSettings.categories, payload]
      };
    },
    addNewTeamBiddingCustomQuestions(state, payload) {
      state.company.funTeamBiddingCustomQuestions = [
        payload,
        ...state.company.funTeamBiddingCustomQuestions
      ];
    },
    setTeamBiddingCustomQuestions(state, payload) {
      state.company.funTeamBiddingCustomQuestions = payload;
    },
    addNewIceBreakerCustomQuestions(state, payload) {
      state.company.funIceBreakerCustomQuestions = [
        payload,
        ...state.company.funIceBreakerCustomQuestions
      ];
    },
    setIceBreakerCustomQuestions(state, payload) {
      state.company.funIceBreakerCustomQuestions = payload;
    },
    toggleSound(state, payload) {
      state.soundOn = payload;
    },
    setAllCompanies(state, payload) {
      state.allCompanies = payload;
    },
    checkTimeOffset(state, payload) {
      state.timeOffset = payload;
    }
  },
  actions: {
    createStripeSession(context, { mode, stripePayload, tokenPayload }) {
      return axios
        .post(`/create-checkout-session-${mode}`, {
          stripePayload,
          tokenPayload
        })
        .then(response => {
          return response.data.id;
        })
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    decodeToken(context, token) {
      return axios.post(`/decode-token`, { token }).then(({ data }) => data);
    },
    encodeToken(context, payload) {
      return axios.post(`/encode-token`, { payload }).then(({ data }) => data);
    },
    sendInviteEmail(context, payload) {
      return axios
        .post(`/send-invite-email`, payload)
        .then(() => true)
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    sendInviteThroughReferral(context, payload) {
      return axios
        .post(`/send-invite-through-referral`, payload)
        .then(() => true)
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    sendCheckoutSuccessEmail(context, payload) {
      return axios
        .post(`/send-checkout-success`, payload)
        .then(() => true)
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    checkTimeOffset({ commit }, date) {
      return axios
        .post(`/check-time-offset`, { date })
        .then(({ data }) => commit('checkTimeOffset', data ? data.difference : 0))
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    sendEmail(context, payload) {
      return axios
        .post(`/send-landing-email`, payload)
        .then(() => true)
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    },
    sendAdminEmail(context, payload) {
      return axios
        .post(`/inform-admin-email`, payload)
        .then(() => true)
        .catch(e => {
          throw new Error(e?.response?.data?.message);
        });
    }
  }
});
