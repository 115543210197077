import { createApp } from 'vue';
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

import App from './App.vue';
import router from './router';
import { i18n } from '@/i18n';
import { store } from '@/store';

import Input from 'primevue/inputtext';
import Upload from 'primevue/fileupload';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import PrimeVue from 'primevue/config';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Chip from 'primevue/chip';
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import DropdownSelect from 'primevue/dropdown';
import Toast from 'primevue/toast';
import Slider from 'primevue/slider';
import Tooltip from 'primevue/tooltip';
import ConfirmDialog from 'primevue/confirmdialog';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import { ColorPicker } from 'vue-color-kit';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/assets/styles/main.scss';

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(store);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.component('Input', Input);
app.component('Upload', Upload);
app.component('Button', Button);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dialog', Dialog);
app.component('DropdownSelect', DropdownSelect);
app.component('ProgressSpinner', ProgressSpinner);
app.component('Textarea', Textarea);
app.component('Sidebar', Sidebar);
app.component('Chip', Chip);
app.component('Toast', Toast);
app.component('Slider', Slider);
app.component('ConfirmDialog', ConfirmDialog);
app.component('InputSwitch', InputSwitch);
app.component('MultiSelect', MultiSelect);
app.component('ColorPicker', ColorPicker);
app.component('RadioButton', RadioButton);
app.component('Checkbox', Checkbox);

app.mount('#app');
