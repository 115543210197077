export default {
  common: {
    newGame: 'New game',
    loading: 'Loading...',
    value: 'Value',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    settings: 'Settings',
    copiedToClipboard: 'Copied to clipboard',
    gameCancelled: 'Game has been successfully cancelled and you got it back in the package.',
    hide: 'Hide',
    options: 'Options',
    startGame: 'START GAME',
    cancelGame: 'Cancel game',
    instructions: 'Instructions',
    waitForLink: 'Wait for link to the game',
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    game: 'game',
    games: 'games',
    gamesMore: 'games',
    lastRound: 'Last round!',
    nextRound: 'Next round begins',
    manualNextPhase: 'Go to next phase manually (if sth broke)',
    areYouSure: 'Are you sure?',
    playerRemoved: 'Player has been removed',
    addAdmin: 'Add admin',
    adminChanged: 'Admin added',
    forceNextPlayer: 'Force next player',
    nextPlayerForced: 'Next player forced',
    players: 'Players',
    removePlayer: 'Remove Player',
    logout: 'Logged out successfully.',
    play: 'Play',
    seconds: 'seconds',
    startNewGame: 'Start new game',
    somethingWrong: 'Something went wrong. Please refresh the page and try again.'
  },
  forgot: {
    success: 'You should get an email with reset password instructions.'
  },
  games: {
    chooseGame: 'Choose the game',
    labels: {
      individual: 'Individual',
      relationships: 'Relationships',
      twoToEight: '3 to 8 players',
      fourToTwenty: '4 to 20 players',
      fourToTen: '4 to 10 players',
      team: 'Team',
      rivalry: 'Competitive',
      icebreaker: 'Icebreaker',
      skills: 'Skills'
    },
    funbusinessgame: {
      description: [
        'Choose a board preset and play a game similar to Monopoly.',
        'Upgrade cards and get some luck with chance cards. Play until first person defaults or for selected period of time.'
      ]
    },
    funteambidding: {
      description: [
        'Team trivia? Get to know your team and their strengths.',
        'Bid the price to answer the question. Win additional money for their team.',
        ''
      ]
    },
    funicebreaker: {
      description: [
        'Get to know your colleagues better thanks to this icebreaker quiz.',
        'Guess what other players answered to given questions.'
      ]
    },
    funteamclues: {
      description: [
        'Based on a well-known game - Codenames.',
        'One of you becomes a spy and gives clues to the rest of the team. They have to find out which words match the clue.'
      ]
    }
  },
  board: {
    outside: {
      chance: 'Chance',
      zus: 'TAX'
    },
    inside: {
      lastRoll: 'Last roll:',
      plays: 'plays',
      turn: 'is rolling',
      rollDices: 'Roll dice'
    },
    gameEndedDefaulted: 'Game ended! One of the players defaulted.',
    gameEndedTimeElapsed: 'Game ended! Time elapsed.',
    actionCard: {
      title: {
        payment: 'Payment!',
        chance: 'Chance Card for {player}!',
        tax: 'Tax for {player}!',
        penalty: 'Penalty for {player}!',
        bonus: 'Bonus for {player}!'
      }
    }
  },
  players: {
    you: 'You',
    cash: 'Cash',
    payroll: 'Payroll',
    cardsOwned: 'cards owned',
    admin: 'Admin'
  },
  boardCard: {
    buy: 'BUY',
    pay: 'PAY',
    sell: 'SELL',
    promote: 'PROMOTE',
    notOwned: 'Not owned',
    ifYouHave: 'If you have',
    toEmploy: 'Purchase price',
    letGo: 'Sell value',
    toPromote: 'Promotion price',
    costForOtherPlayers: 'Cost for other players',
    saleValue: 'Sale value'
  },
  newGame: {
    name: 'Your name',
    nickname: 'Your Nickname',
    job: 'Your Job',
    strength: 'In what knowledge category are you an expert?',
    weakness: 'In what knowledge category are you a loser?',
    questions: 'Questions',
    startMoney: 'Start money for each player',
    playAudioForNextPlayer: 'Play audio for next player',
    duration:
      'Game duration (minutes). Leave empty if you want to play until first player goes bankrupt',
    chooseAvatar: 'Choose avatar',
    minutes: 'Minutes',
    clear: 'Clear',
    startNewGame: 'Start new game',
    adjustGame: 'Customize',
    copyLink: 'Copy link to the game',
    goToGame: 'Go to game',
    generateGoogleMeet: 'Generate Google Meet link',
    selectAutomatically: 'Select questions automatically',
    selectManually: 'Select questions manually',
    numberOfQuestions: 'Number of questions in the game',
    categories: 'Categories',
    selectedQuestions: 'Selected questions',
    playTheGame: 'Do you want to play the game? If checked, you will play the game with others.',
    limitNumberOfMatching:
      'Limit the number of matching options that players will see. Check this option if more than 8 people play.',
    firstPlayerToBeAdmin:
      'You are the first player to join the game and you are the game admin. You will be able to start the game, remove players if needed and do additional actions.',
    limitNumberOfMatchingLabel: 'Limit:',
    estimatedTime: `If no specific game duration is selected, you will play until first player goes bankrupt. Usually it takes from 30 to 60 minutes, but depends on the players' strategy.`,
    estimatedGameTimeIs: '* Estimated game time is approximately:',
    for: 'For',
    players: 'players',
    moreThanEight: 'more than 8 players',
    estimatedMinutes: 'minutes',
    shouldLimitTimeForGuessing: 'Do you want to limit time for guessing?',
    shouldLimitTimeForGivingClue: 'Do you want to limit time for giving a clue to the team?',
    limitInSeconds: 'Limit (seconds)'
  },
  joinGame: {
    createProfile: 'Create your profile',
    avatar: 'Choose avatar',
    button: 'Join game',
    nameTaken: 'Name taken by another player'
  },
  auth: {
    logIn: 'Log in to RemoteFun',
    registerForGame: 'Register for RemoteFun',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    boardTemplate: 'Board Template',
    chanceTemplate: 'Chance Cards Template',
    login: 'Login',
    register: 'Register',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
    companyName: 'Company name',
    referralCode: 'Referral code',
    companyAddress: 'Company address',
    taxId: 'Tax ID (NIP in Poland) - Without country code',
    zipCode: 'ZIP Code',
    city: 'City',
    country: 'Country'
  },
  buyOffer: {
    card: 'Card',
    currentOwner: 'Current owner',
    askingPlayer: 'Asking player',
    offer: 'Offer',
    wantToSell: 'Do you want to sell?'
  },
  auction: {
    auction: 'Auction',
    bid: 'Bid',
    yourBid: 'Your bid',
    currentWinner: 'Current winner',
    endingIn: 'Ending in',
    notEnoughMoney: 'You do not have {bid} in your pocket',
    bidTooSmall: 'Bid is smaller than current winning bid',
    nobodyWon: 'Nobody won the card',
    playerWon: '{name} won auction for {value}'
  },
  endResults: 'Results',
  endResultsThanks: 'Thanks to everybody who took part in the game!',
  endResultsFeedbackPlaceholder: 'Write your feedback here (optional)',
  endResultsFeedbackHeading: 'How would you rate your experience?',
  endResultsGiveFeedback: 'Give Feedback',
  endResultsSendFeedback: 'Send Feedback',
  endResultsSendFeedbackWarning: 'Please provide either rating or feedback message.',
  endResultsThanksForFeedback: 'Thanks for feedback',
  funBusinessGameShortInstructions: [
    'The game works similarly to Monopoly 😲. Take turns in rolling the dice, buy cards, upgrade them and take chance cards.',
    'When you stand on a manual action card, you can either buy it or not perform any action. If the card is owned by another player, you will have to pay them.',
    'Before each roll you are able to perform one action on one of cards you own. You do not need to own all cards from a given color to start upgrading. Preview your card by clicking on it to upgrade or downgrade it.',
    'When first person defaults or when time ends, game will end and player with biggest value wins. Value is calculated as cash + half of the price of cards and upgrades.',
    'Staying on BONUS (top-left) gives accumulated money to the player standing on it. Money is added to bonus based on chance card fines and people going through payroll (every payroll gives 10 to accumulation)',
    'Have fun!'
  ],
  instructions: {
    hide: 'Hide',
    title: 'Instructions',
    previousStep: 'Previous',
    finish: 'Finish',
    nextStep: 'Next',
    content: [
      [
        'General',
        'Welcome to FunBusinessGame. The game works similarly to Monopoly 😲.',
        'Take your chance, buy cards and upgrade them.'
      ],
      [
        'Gameplay',
        'Each player takes a turn by rolling the dices.',
        'You can go either to a board field where you can buy a card, take chance, bonus, pay somebody or get payroll.',
        'Before each roll you are able to perform one action on one of your cards - upgrade, sell or ask somebody to sell a card to you.',
        'When first person defaults, game ends and player with biggest value wins.'
      ],
      [
        'Additional information',
        'Teams can be built as soon as you own a card. There is no need to own all cards from given color.',
        'Each card has base charge of 10% of base price if it is not upgraded.',
        'If you roll the same values on both dices, you get additional round.',
        'Individual cards (end of the board) cannot be upgraded and always charge a bigger amount.',
        'Click on cards to preview them.',
        'Click on player to see their cards and value',
        'Staying on BONUS will give some accumulated money based on people paying for chance cards and people going through payroll (every payroll gives 10 to accumulation)/'
      ],
      ['Final thoughts', 'Have fun!']
    ]
  },
  misc: {
    language: 'Language',
    gameLog: 'Game log',
    possibleActions: 'Possible actions',
    timeLeft: 'Time left',
    holidaysTheme: 'Holidays theme',
    hideHolidaysTheme: 'Hide theme'
  },
  log: {
    bonusAlert: 'Current player took the bonus!',
    bonusDescription: 'Took bonus money - {value}',
    benchAlert: 'Oops. Current player pays quarter of payroll.',
    benchDescription: 'Penalty card - paid quarter of payroll',
    payTaxAlert: 'Current player pays taxes - half of payroll, it goes to BONUS accumulation',
    payTaxDescription: 'Paid tax - {value}',
    tookChanceCard: 'Took chance card',
    buy: '{card} card bought',
    sell: '{card} card sold',
    askToBuyHowMuch: 'How much are you willing to pay {name} for {card}?',
    notEnoughMoney: 'Not enough money',
    addedToTeam: '{category} added to {card}',
    changedToTeam: '{fromCategory} changed to {toCategory} in {card}',
    removedFromTeam: '{category} removed from {card}',
    playerPays: '{fromName} pays {toName} {value} for {card}',
    paidTo: 'Paid {value} to {name}',
    rolled: 'Rolled {diceOne} and {diceTwo}',
    payroll: 'Got {value} for payroll',
    payrollAlert: 'You get payroll - {value}'
  },
  actions: {
    BUY: 'Buy',
    PASS: 'Pass',
    AUCTION: 'Start Auction',
    ASK_TO_BUY: 'Ask to buy',
    SELL: 'Sell team',
    GET_CATEGORY_ONE: 'Get Junior',
    PROMOTE_CATEGORY_ONE: 'Promote Junior',
    LET_GO_CATEGORY_ONE: 'Let Go Junior',
    GET_CATEGORY_TWO: 'Get Mid',
    PROMOTE_CATEGORY_TWO: 'Promote Mid',
    LET_GO_CATEGORY_TWO: 'Let go Mid',
    GET_CATEGORY_THREE: 'Get Senior',
    PROMOTE_CATEGORY_THREE: 'Promote Senior',
    LET_GO_CATEGORY_THREE: 'Let go Senior',
    GET_CATEGORY_FOUR: 'Get Lead',
    LET_GO_CATEGORY_FOUR: 'Let go Lead'
  },
  adminPanel: {
    onlyDesktop: 'Dashboard is available only on bigger devices. (bigger or equal to 1152px)',
    common: {
      featureNotAvailable: 'This feature is not yet available'
    },
    shortInstructions: [
      'On dashboard you can see information about your company and your package.',
      'For all games, you can start new games, see existing games, create custom questions and adjust settings',
      'Info & Branding - add your logo, change branding colors in games and set of avatars',
      'Users - manage users that have access to this account. You can also apply who can create games and who has access to this dashboard (admins)',
      'On the bottom left-hand side, you can also see other actions, like support contact, changing language and logging out.'
    ],
    registerForm: {
      buyFirstPackage: "To buy your first package, we'll need to set up an account for you.",
      getFreePackage: 'Set up an account to use RemoteFun for free.',
      needInvoice: 'I need a VAT invoice',
      removeInvoice: 'Remove invoice data',
      fillRequired: 'Fill in all required fields.',
      passwordDontMatch: "Passwords don't match.",
      fillRequiredForInvoice: 'Fill in all required fields for invoice.'
    },
    activeGames: {
      title: 'Active / Played games',
      noActivePackage: "You don't have an active package",
      buyGames: 'Buy games!',
      playedAllGames: "You've played all games in your package.",
      buyMoreGames: 'Buy more games!',
      headers: {
        players: 'Players',
        createdBy: 'Created By',
        status: 'Status',
        createdOn: 'Created on',
        timeLeft: 'Time left to play',
        link: 'Link'
      },
      ended: 'Ended',
      cancelled: 'Cancelled',
      notYetStarted: 'Not yet started',
      timeEnded: 'Time ended',
      inProgress: 'In progress',
      goToGame: 'Go to game',
      copyLink: 'Copy link',
      noGamesAddedYet: 'No games added yet.',
      startFirstGame: 'Start your first game'
    },
    dashboard: {
      yourPlan: 'Your plan',
      mode: 'Mode',
      cancel:
        'To cancel subscription, for now please contact support - {value}. This feature will be automatic in the near future.',
      companyId: 'Company ID:',
      yourSubscription: 'Your subscription:',
      gamesLeft: 'Games left:',
      boughtOn: 'Joined on:',
      packageRenewesOn: 'Package renewes on:',
      packageExpiresOn: 'Package expires on:',
      nextPayment: 'Next payment',
      unlimited: 'Unlimited',
      needMoreGames:
        'Need more games? Contact us for a special offer. Soon this feature will be automated',
      users: 'Users',
      gamesPlayed: 'Games played',
      referralMoney: 'Referral money'
    },
    navigation: {
      support: 'Support contact',
      language: 'Language',
      logout: 'Log out',
      dashboard: 'Dashboard',
      games: 'Games',
      listOfCards: 'Board settings',
      cardsUpgradeConfig: 'Cards upgrading settings',
      chanceCards: 'Chance Cards',
      settings: 'Settings',
      leaderboard: 'Leaderboard',
      customQuestions: 'Custom Questions',
      questions: 'Questions',
      companySettings: 'Company-wide settings',
      infoBranding: 'Info & Branding',
      users: 'Users',
      getFreeCoins: 'Get free RemoteCoins!',
      help: 'Help and Feedback'
    },
    branding: {
      title: 'Branding settings',
      logo: 'Logo',
      logoChoose: 'Choose logo (PNG, JPG)',
      colors: 'Colors',
      avatars: 'Avatars pack selection',
      brandingUpdated: 'Branding updated successfully.',
      logoUpdated: 'Logo updated successfully.',
      avatarsUpdated: 'Avatars updated successfully.',
      selected: 'selected',
      companyColor: 'Your brand color'
    },
    users: {
      title: 'Manage users',
      inviteUser: 'Invite user',
      headers: {
        email: 'Email',
        status: 'Status',
        roles: 'Roles',
        actions: 'Actions'
      },
      admin: 'Admin',
      gameCreator: 'Game creator',
      removeUser: 'Remove user',
      invite: 'Invite',
      copyInviteLink: 'Copy invitation link',
      copyInviteLinkTooltip:
        'In case of trouble with email delivery, use this action to copy and send manually.',
      alreadyExists: 'Already exists in organisation',
      inviteSent: 'Invite email sent to {email}',
      roleUpdated: 'Role updated.',
      takeAwayRights: 'You cannot take away permissions from yourself.',
      pending: 'Invitation pending',
      active: 'Active'
    },
    referral: {
      title: 'Referral Program',
      useBudget: 'Budget will be useful when app is not free.',
      current: 'Future referral budget',
      receiveMoney: 'Receive { amount } { currency } in RemoteCoins for every referral!',
      sharingCaring:
        "Sharing is caring. As a new business we're trying to get new users. You can help with that.",
      discountsAddUp: 'Discounts add up. The more your refer, the cheaper your fun becomes.',
      steps: {
        referTitle: 'Refer',
        referDescription: 'Invite through the form below or send your referral code.',
        getMoneyTitle: 'Get the money',
        getMoneyDescription:
          "You'll get the money once your referred company registers for RemoteFun",
        buyGamesFreeTitle: 'Buy games for free',
        buyGamesFreeDescription:
          "You'll see here and on the dashboard how much you've accumulated. Buy next games with discount."
      },
      invite: 'Invite your friends or fellow company',
      send: 'Send',
      yourCode: 'Your Referral Code',
      copyCode: 'Copy code',
      orShare: 'or share through',
      referralUsed: 'Your referral was used!',
      copied: 'Copied to clipboard.',
      inviteEmailSent: 'Email sent successfully.',
      inviteEmailError:
        'Problem occurred during sending the email. Try again. If problem persists, contact support.'
    },
    help: {
      title: 'Help and Feedback',
      files: {
        title: 'Files to download:',
        brochure: 'Brochure',
        faq: 'FAQ',
        instructions: 'Instructions for games'
      },
      feedback: 'Want to leave feedback?',
      howToStart: {
        question: 'How to start using RemoteFun?',
        answers: [
          'On the left navigation panel you can see our 4 available games. Click on each of them and you will be able to start the game.',
          'When you provide your name and other details, send the link to other players.',
          'Each player (including you) will see game instructions at the beginning of the game.'
        ]
      },
      howToCustomizeGames: {
        question: 'How to customize the app and games?',
        answers: [
          'For each game you can do some customizations. Click on the game in the menu to see its options.',
          'In FunBusinessGame you can adjust the game board, card upgrade settings and general settings. In FunTeamBidding and FunIceBreaker you can add your own questions and general settings.',
          'If you want to change the app-wide colors and add your logo, go to Info & Branding in the menu and change it.'
        ]
      }
    },
    businessGame: {
      boardConfig: {
        headerTitle: 'Board settings',
        selectNewTemplate: 'Select new template',
        boardTemplate: 'Board template',
        apply: 'Apply',
        saveAsNew: 'Save as new template',
        templateName: 'Template Name',
        selectOtherTemplate: 'Select other template',
        saveCurrentConfig: 'Save current board as new template',
        previewBoard: 'Preview Board',
        edit: 'Edit',
        preview: 'Preview',
        editor: 'Editor',
        saveCard: 'Save card',
        position: 'Position',
        type: 'Type',
        title: 'Title',
        section: 'Section',
        sectionColor: 'Section color',
        price: 'Net price',
        baseCharge: 'Base Charge',
        color: 'Color',
        canBeBought: 'Can be bought',
        prices: 'Prices',
        purchase: 'Purchase',
        sell: 'Sell',
        promote: 'Promote',
        chargeFor: 'Charge for',
        newTemplateSelected: 'New template selected successfully.',
        customTemplateAdded: 'Custom template {name} added successfully.',
        boardUpdated: 'Board config updated successfully.'
      },
      chanceCards: {
        title: 'Chance Cards',
        addNew: 'Add new Chance Card',
        editor: 'Editor',
        saveCard: 'Save Card',
        id: 'ID',
        descriptionEn: 'Text (English)',
        descriptionPl: 'Text (Polish)',
        descriptionEnPlaceholder: 'Write your English chance card description here',
        descriptionPlPlaceholder: 'Write your Polish chance card description here',
        action: 'Action',
        config: 'Config',
        moneyValue: 'Money/Round value',
        type: 'Type',
        conditionValue: 'Condition value',
        from: 'From',
        to: 'To',
        max: 'Max',
        chance: 'CHANCE',
        updated: 'Chance cards updated.',
        removed: 'Chance card removed.',
        wantToProceed: 'Are you sure you want to proceed?',
        confirmation: 'Confirmation',
        actionsLabels: {
          addMoneyToCurrentPlayer: 'Add Money To Current Player',
          addRoundToCurrentPlayer: 'Add Round To Current Player',
          removeMoneyFromCurrentPlayer: 'Remove Money From Current Player',
          addToPayroll: 'Add To Payroll',
          removeFromPayroll: 'Remove From Payroll',
          removeMoneyFromCurrentPlayerIfConditions:
            'Remove Money From Current Player If Conditions',
          addMoneyToPayrollIfConditions: 'Add Money To Payroll If Conditions',
          addMoneyToCurrentPlayerIfConditions: 'Add Money To Current Player If Conditions',
          changeTeamMembers: 'Move categories within card'
        },
        typeOptions: {
          // teamFieldMatch: 'Team Field Match',
          // teamFieldDontMatch: 'Team Field Dont Match',
          cardsMax: 'Has less or equal to X cards, then'
        },
        tooltips: {
          moneyValue: 'Amount of money/rounds added/removed',
          type: 'Type of action, which changes sth in the game',
          conditionValue: 'Number that sets the maximum of X based on above type',
          moneyValueIfCondition: 'Money added/removed if condition passed',
          from: 'Category which will give out resources',
          to: 'Category that will get resources',
          max: 'Maximum of resources that will move between categories'
        },
        newTemplateSelected: 'New chance cards template selected successfully',
        selectNewTemplate: 'Select new template',
        chanceTemplate: 'Chance Cards template',
        apply: 'Apply',
        saveAsNew: 'Save as new template',
        templateName: 'Template name',
        selectOtherTemplate: 'Select other chance cards template',
        saveCurrentConfig: 'Save current cards as new template'
      },
      cardUpgrade: {
        categoryOne: 'Category no. 1',
        categoryTwo: 'Category no. 2',
        categoryThree: 'Category no. 3',
        categoryFour: 'Category no. 4',
        english: 'English',
        polish: 'Polish',
        preview: 'Preview',
        save: 'Save',
        title: 'Cards upgrading settings',
        saved: 'Cards upgrading settings saved.',
        description:
          'Some of the cards on the board can be upgraded. Here is your current upgrade wording:'
      }
    }
  },
  checkout: {
    withRegister: {
      title: 'Checkout',
      youAreBuying: ['Free account until', 'end of 2022'],
      downloadingBoard: 'Downloading board and chance configs...',
      creatingUser: 'Creating a user account for you...',
      creatingCompany: 'Creating your company in our database...',
      errorAddingDocument: 'Error adding document',
      redirectingToPayment: 'Redirecting you to payment...',
      payAndRegister: 'Register',
      subscription: 'one-month, renewing subscription',
      oneTime: 'as one-time purchase',
      freePackage: 'Free package'
    },
    price: 'Net Price:',
    priceGross: 'Gross price',
    success: {
      title: 'Thank you!',
      description: 'Here is a summary of your purchase',
      yourSubscription: 'Your subscription:',
      boughtOn: 'Joined on:',
      renewesOn: 'Package renewes on:',
      backToDashboard: 'Back to dashboard',
      referralBonus: 'Referral bonus',
      mode: 'Mode',
      invoice: 'Invoice will be sent manually by our team. In the future, this will be automatic.',
      toast:
        'Registered successfully. You can now use RemoteFun to play and customize all games. If you need help getting started, check out the Help section.'
    },
    fail: {
      title: 'Something unexpected happened!',
      charged:
        "If you were charged, please contact support immediately {value}. We'll manage this problem.",
      notCharged:
        "If you weren't charged, please contact us to manually manage the payment - {value}"
    }
  },
  funDice: {
    roll: {
      turn: `{ name }'s turn"`,
      putDicesInCup: 'Put dices in the cup',
      roll: 'Roll',
      rollsLeft: '{ value } rolls left',
      fillOrCross: 'Fill in a cell or end the round by crossing out something.',
      fillOrSave:
        'Fill in a cell or save specific dices by clicking on them, so that they are not used in the next roll.'
    },
    results: {
      schoolSummary: 'School summary',
      sum: 'Sum',
      school: {
        ones: 'Ones',
        twos: 'Twos',
        threes: 'Threes',
        fours: 'Fours',
        fives: 'Fives',
        sixes: 'Sixes'
      },
      rest: {
        onePair: 'One pair',
        twoPairs: 'Two pairs',
        three: 'Three of a kind',
        four: 'Four of a kind',
        fullHouse: 'Full House',
        small: 'Small straight',
        large: 'Large Straight',
        chance: 'Chance',
        general: 'General'
      }
    }
  },
  funIceBreaker: {
    points: 'Points',
    currentResults: 'Current results',
    questionsLeft: '{value} questions remaining',
    waitForOtherPlayers: 'Thanks! Wait for other players.',
    welcome: ['Welcome to FunIceBreaker!', 'Soon you will know your coworkers better!'],
    shortInstructions: [
      'Wait for other players and get to know each other.',
      'In Phase 1, answer the question provided.',
      "In Phase 2, match people with their proper answers, using the 3-line icon (drag and drop to sort players names) or select other players' lies.",
      `Talk about your answers and get to know each other better!`
    ],
    instructions: {
      guy: {
        SINGLE: {
          QUESTION:
            'Answer the question and wait for other players to answer as well. You can see who already answered in current results.',
          MATCHING:
            'Match the answers with people. Drag and drop people to change their position. The quicker you do it, the more points you get.',
          STORY: 'Talk about your answers to get to know other players better.'
        },
        TRUTHLIE: {
          QUESTION:
            'Answer the question and wait for other players to answer as well. You can see who already answered in current results.',
          MATCHING:
            'For each person, select a lie that they provided and submit your guessing. The quicker you do it, the more points you get.',
          STORY: 'Talk about your answers to get to know other players better.'
        }
      }
    },
    phases: ['Answer the question', 'Match people with answers', 'Tell the story'],
    phaseOf: ['Phase', 'of'],
    youAreGameCreator: 'You are the game creator, but you chose not to play the game.',
    question: {
      placeholder: 'Answer here...',
      truthPlaceholder: 'Write truth here',
      liePlaceholder: 'Lie here',
      changeAnswer: 'Change Answer',
      answer: 'Answer',
      write2Truths1Lie: 'Write 2 truths and 1 lie.'
    },
    matching: {
      titleSingle: 'Move the names up and down to match answers',
      titleTruthLie: 'For every person, select where they lied',
      speakSingle: 'Move the names up and down to match the answers',
      speakTruthLie: 'For every person, select where they lied',
      waitForOtherPlayers: 'Thanks! Please wait for other players to answer.',
      send: 'Submit matching',
      youWillGetPoints: 'You will get {value} points for every match',
      similarAnswers: [
        'A few players answered the same thing.',
        'All matching answers will be accepted.'
      ]
    },
    story: {
      title: ['Talk about your answers!', '{name}, maybe you could start?'],
      youGetPoints: 'You get {value} points for last round',
      startNextRound: 'Start next round',
      answered: 'answered',
      lied: 'lied, writing',
      endGame: 'End game',
      adminStartsNextRound: 'Admin will start the next round.',
      newLeader: ['Congrats to {name}. Keep it up!', 'We have a new leader'],
      mostPoints: ['{name} got most points for last round - {points} points!', 'Congrats!']
    },
    customQuestions: {
      type: 'Type of question',
      typeTooltip: 'Choose whether the question should be single answer or write 2 truths, 1 lie.',
      tooMany: 'Too many questions selected. You can select between 3 and 15 questions per game',
      tooFew: 'Too few questions selected. You can select between 3 and 15 questions per game',
      types: {
        SINGLE: 'Single answer',
        TRUTHLIE: '2 Truths 1 Lie'
      },
      updated: 'Question saved successfully',
      wantToProceed: 'Are you sure you want to remove this question?',
      confirmation: 'Are you sure?',
      removed: 'Question removed',
      questionWrongConfig: 'Some questions do not have description in at least 1 language'
    },
    settings: {
      noOfQuestions:
        'Default number of questions - you can also select questions directly while creating the game'
    }
  },
  funTeamBidding: {
    gameAlreadyStarted: 'Game has already started. You can only join if game is in Question phase',
    questionsLeft: '{value} questions remaining',
    peopleStillJoining: 'People who joined, but are yet without a team',
    goodAnswer: '{name} answered correctly. {jackpot} is added to their account.',
    playedVaBanque: '{name} played va banque! They answer for double jackpot.',
    teams: {
      label: 'Teams',
      add: 'Add new team',
      join: 'Join team',
      leave: 'Leave team',
      changeName: 'Change team name',
      newName: 'New name',
      change: 'Change',
      nameTaken: 'This name is taken',
      emptyName: 'Name cannot be empty'
    },
    player: {
      captain: 'Captain',
      makeCaptain: 'Make captain'
    },
    shortInstructions: [
      'Join a team. Then wait for other players from your group and get to know each other. ',
      'Phase 1 automatically selects category of a question.',
      'Phase 2 starts the clock. Team captain bids the price, so talk to them. Every new bid resets the clock.',
      'You can take loan from bank or play va banque. Winner of bidding can answer the question and get the jackpot points.'
    ],
    instructions: {
      guy: {
        WELCOME: `Join a team and wait for other players.\n\nSplit yourselves into teams. More than 2 teams provide a better experience.\n\nEmpty teams will be removed when game is started.`,
        SELECTING_CATEGORY: 'Category is selected automatically and randomly.',
        BIDDING:
          'Talk to your captain, who can bid the price. Winner can answer the question and get jackpot value. Each bid resets the clock to {value} seconds',
        ANSWER: 'Answer the question.'
      }
    },
    phases: ['Category selection', 'Bidding', 'Answering'],
    phaseOf: ['Phase', 'of'],
    selectedCategory: 'Selected category',
    nextPhase: 'Next phase will begin shortly.',
    bidding: {
      jackpot: 'Jackpot',
      history: 'Bidding history',
      highest: 'Highest bidding team',
      timeLeft: 'Time left',
      seconds: 'Seconds',
      captain: "You're the captain! Talk to your team and make decisions.",
      bid: 'Bid',
      warning: "You have to bid more than current highest value or you don't have enough money",
      chosenCategory: 'Chosen category is {category}',
      vaBanque: 'Va Banque',
      youResigned: 'You resigned from the auction.',
      auctionMenu: 'Auction menu',
      quickActions: 'Quick actions',
      resign: 'Resign',
      playVaBanque: 'Play va banque',
      availableIn: 'in {value}',
      bank: 'Bank',
      loanValue: 'Loan value',
      takeLoan: 'Get the loan',
      biddingPrice: 'Bidding value',
      interest: 'Interest (10% each round). Max debt is {maxDebt}',
      repayValue: 'Repay value',
      repay: 'Repay',
      wonAuction: 'You won the auction!',
      teamWon: '{name} won the auction and will start next phase',
      startNextPhase: 'Start next phase to see the question',
      teamWonWillStart: '{name} will start the next phase.',
      startAnswerPhase: 'Start Answer phase',
      resigned: 'Team {name} resigned from bidding',
      cantHaveMoreDebt: 'You cannot have more than {maxDebt} in debt.',
      cantRepayMore: 'You cannot repay more than you owe.',
      loanSuccess: 'You successfully took {value} loan',
      repaySuccess: 'You successfully repayed {value} of your debt'
    },
    answer: {
      timeLeft: 'Time left to answer',
      answers: "'s captain answers"
    },
    categories: {
      general: 'General Knowledge',
      film: 'Film',
      music: 'Music',
      nature: 'Science & Nature',
      computers: 'Computers',
      mathematics: 'Mathematics',
      mythology: 'Mythology',
      sports: 'Sports',
      geography: 'Geography',
      history: 'History',
      politics: 'Politics',
      animals: 'Animals',
      custom: 'Custom'
    },
    debt: 'Debt',
    customQuestions: {
      multiple: 'Multiple options',
      yesNo: 'Yes / No',
      updated: 'Question saved.',
      wantToProceed: 'Do you want to delete the question?',
      confirmation: 'Are you sure?',
      removed: 'Question deleted.',
      notValidAnswer: [
        'There are errors in some questions. For some questions:',
        'Please check this before creating games.'
      ],
      noTextInSomeQuestionText: '* There is not description in at least 1 language',
      wrongOptionsText: '* There are not enough options created',
      noValidOptionSelectedText: '* Do not have valid answer selected',
      addedToCustom: 'Successfully added to presets.'
    },
    settings: {
      categories: 'Default Categories (can later be adjusted during start of the game)',
      moneyForStart: 'Money for start of the game for each team',
      moneyTakenStartOfBidding: 'Money taken for beginning of each bidding',
      resetTime: 'Reset time after every new bid',
      maxDebt: 'Maximum loan value that the team can take'
    }
  },
  funTeamClues: {
    phasesDescriptions: [
      '{spyName} gives a clue to Team {activeTeamName}.',
      'Team {activeTeamName} guesses the answers based on a clue.'
    ],
    shortInstructions: [
      'FunTeamClues is about good teamwork. Join a team. In each team, one of you has to become a spy, the rest can be operatives.',
      'Spies can see which words belong to which team. Based on that, they have to give a one-word clue and a number of words so that their team can guess as many as they can each round.',
      'Teams take turns and the team which selects all of their words first, wins.',
      'There is one killer word, which will make you lose the game if you select it.'
    ],
    instructions: {
      guy: {
        WELCOME: `Join a team. In each team, one of you has to become a spy, the rest can be operatives.`,
        GIVE_CLUE:
          'Spy of the active team is giving a clue and number of words their team has to answer.',
        GUESSING: `The team is now guessing the words based on their spy's clue.`
      }
    },
    teams: {
      nameTaken: 'This name is taken'
    },
    congratsYouGuessed: 'Correct word was selected!',
    killerChosen: 'Killer was chosen. {activeTeamName} Team wins.',
    neutralWord: 'Selected word was neutral.',
    wrongWord: 'Selected word belonged to another team.',
    teamWins: 'Team {winningTeamName} wins!',
    nextRound: `Next round begins. It's time for Team {activeTeamName}.`,
    clue: 'Clue',
    numberToGuess: 'Number of words to guess',
    answers: 'To answer:',
    wordsLeft: 'Words left:  ',
    operatives: 'Agents',
    spy: 'Spy',
    giveClue: 'Send the clue',
    chooseOneSpy: 'You have to choose one spy from your team who will be giving clues to the rest.',
    noTimeLimit:
      'Giving clue and answering are not time-limited, you can limit the time by yourself. This feature will be available later if needed.',
    log: {
      gaveClue: ' provided a clue -',
      guessed: ' guessed'
    },
    notAllInfoProvided: 'Provide a clue and number of words to guess.',
    hasToBeNumber: 'Number of words to guess has to be a number.',
    becomeOperative: 'Become an agent',
    becomeSpy: 'Become a spy',
    gameEnded: 'Game ended - Team {team} won the game.',
    maxNineGuess: 'Maximum guess number can be 9'
  },
  gamesCommon: {
    customQuestions: 'Custom Questions',
    addNewCustomQuestion: 'Add new custom question',
    id: 'ID',
    questionEn: 'Question (English)',
    questionPl: 'Question (Polish)',
    type: 'Type',
    actions: 'Actions',
    editor: 'Editor',
    validAnswer: 'Valid answer',
    questionType: 'Question type',
    options: 'Options',
    saveQuestion: 'Save question',
    questionTextEn: 'Question text (English)',
    questionTextPl: 'Question text (Polish)',
    validAnswerOne: 'Valid answer - select one',
    noCustomQuestions: 'No custom questions added yet',
    welcome: 'Welcome to the game',
    inviteOthers: `Invite other players! Send them the link to the game.`,
    youWillStart: `You will be able to start the game when everybody's joined.`,
    adminWillStart: `Game admin will start the game when everybody's joined.`,
    startNewGameWithSelected: 'Start new game with selected questions',
    startNewGameWithSelectedTooltip:
      'You will be able to create the game when you select between 3 and 10 questions.'
  },
  shortInstructions: 'Short instructions',
  errors: {
    somethingWentWrong: 'Something went wrong. Please reload the page.',
    noPermissions: 'You do not have permissions to access this page.',
    provideEmail: 'Provide email.',
    provideEmailPassword: 'Provide email and password.',
    provideEmailPasswords: 'Provide email and passwords.',
    noInvite: 'There is no invitation for this email.',
    noPackageOrGames: `You have no package or no {game} games left in the package`,
    atLeastQuestions: `At least {value} questions have to be selected for this game`,
    noGameWithLink: 'No game with that link'
  },
  warnings: {
    atLeastCategories: 'You have to select at least {number} categories',
    noPermissionsToCreateGames:
      "You don't have permissions to create games. Ask your organisation admin to provide you such permissions in admin panel.",
    cantBeLongerThan: 'Inputs cannot be longer than {value}',
    tooLowMoneySuggestion:
      'We strongly recommend to create a game with more money for players than 2000 in order to allow them to upgrade cards and make the game more fun.',
    tooFewCustomQuestions:
      'You do not have this many custom questions created. You selected {selected}, while you have {created} custom questions created'
  },
  success: {
    gameCreated: 'Game created successfully. Invite other people and start the game.',
    saved: 'Saved',
    selectedQuestionsAdded:
      'Selected questions have been added. Fill in other required information and create the game.'
  },
  toasts: {
    success: 'Success',
    information: 'Information',
    warning: 'Warning',
    error: 'Error'
  },

  mailing: {
    from: 'from RemoteFun Virtual Team Building',
    hi: 'Hi',
    myNameIs: [
      'my name is {salesPerson} and I am the {salesPersonJob} of RemoteFun. We recently ',
      '<b>launched a new virtual team building platform</b>',
      ' to strengthen team relationships and boost employee engagement.',
      ` I'm reaching out because we think`,
      ` {clientCompany} would benefit a lot`,
      ` from using our platform. {personalizedSentence}`
    ],
    areYouOpen:
      'Are you open to a 15-minute call this or next week to discuss ways RemoteFun could more specifically help your business?',
    scheduleMeeting: 'You can schedule something suitable for you here.',
    idLikeToSpeak:
      'I’d like to speak with someone from {clientCompany}, who is responsible for handling activities that are related to building a community or is from HR/People team.',
    ifThatsYou:
      'If that’s you, are you open to a 15-minute call this or next week to discuss ways RemoteFun could more specifically help your business or could you point me to the right person?',
    freePackage: `We have a <b>free test package ready for you</b> with one instance of all our activities. I think it's a great way to test the solution! Let me know and I'll send you the details.`,
    animationWebsite:
      'Here’s our animation and our website that highlight what we do and how it can benefit your employees and your business.',
    answer: 'I’d very much appreciate your answer.',
    cheers: 'Cheers,',
    animation: 'Our video',
    website: 'Website',
    scheduleCall: 'Schedule a call with us',
    founder: 'founder',
    coFounder: 'Co-founder',
    founderCapitalized: 'Founder',
    coFounderCapitalized: 'Co-founder',
    subject: 'RemoteFun - Personalize your team building - invitation to cooperate with us'
  }
};
