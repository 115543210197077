import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // {
  //   path: '/fundice/:gameId',
  //   name: 'fundice-game',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'fundice-game' */ '@/views/fundice/FunDiceGame.vue'
  //     )
  // },
  // {
  //   path: '/fundice/new',
  //   name: 'fundice-new-game',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'fundice-new-game' */ '@/views/fundice/FunDiceNewGame.vue'
  //     )
  // },
  {
    path: '/play-without-register',
    name: 'play-without-register',
    component: () => import(/* webpackChunkName: 'common' */ '@/views/PlayWithoutRegister.vue')
  },
  {
    path: '/wait-for-game-link',
    name: 'wait-for-game-link',
    component: () => import(/* webpackChunkName: 'common' */ '@/views/WaitForGameLink.vue')
  },
  {
    path: '/funteamclues/:companyId/:gameId',
    name: 'funteamclues-game',
    component: () =>
      import(/* webpackChunkName: 'funteamclues' */ '@/views/funteamclues/FunTeamCluesGame.vue')
  },
  {
    path: '/funteamclues/new',
    name: 'funteamclues-new-game',
    component: () =>
      import(/* webpackChunkName: 'funteamclues' */ '@/views/funteamclues/FunTeamCluesNewGame.vue')
  },
  {
    path: '/funteamclues/new-without-register',
    name: 'funteamclues-new-game-without-register',
    component: () =>
      import(
        /* webpackChunkName: 'funteamclues' */ '@/views/funteamclues/FunTeamCluesNewGameWithoutRegister.vue'
      )
  },
  {
    path: '/funteambidding/:companyId/:gameId',
    name: 'funteambidding-game',
    component: () =>
      import(
        /* webpackChunkName: 'funteambidding' */ '@/views/funteambidding/FunTeamBiddingGame.vue'
      )
  },
  {
    path: '/funteambidding/new',
    name: 'funteambidding-new-game',
    component: () =>
      import(
        /* webpackChunkName: 'funteambidding' */ '@/views/funteambidding/FunTeamBiddingNewGame.vue'
      )
  },
  {
    path: '/funteambidding/new-without-register',
    name: 'funteambidding-new-game-without-register',
    component: () =>
      import(
        /* webpackChunkName: 'funteambidding' */ '@/views/funteambidding/FunTeamBiddingNewGameWithoutRegister.vue'
      )
  },
  {
    path: '/funicebreaker/:companyId/:gameId',
    name: 'funicebreaker-game',
    component: () =>
      import(/* webpackChunkName: 'funicebreaker' */ '@/views/funicebreaker/FunIceBreakerGame.vue')
  },
  {
    path: '/funicebreaker/new',
    name: 'funicebreaker-new-game',
    component: () =>
      import(
        /* webpackChunkName: 'funicebreaker' */ '@/views/funicebreaker/FunIceBreakerNewGame.vue'
      )
  },
  {
    path: '/funicebreaker/new-without-register',
    name: 'funicebreaker-new-game-without-register',
    component: () =>
      import(
        /* webpackChunkName: 'funicebreaker' */ '@/views/funicebreaker/FunIceBreakerNewGameWithoutRegister.vue'
      )
  },
  {
    path: '/funbusinessgame/:companyId/:gameId',
    name: 'funbusinessgame-game',
    component: () =>
      import(/* webpackChunkName: 'funbusinessgame' */ '@/views/funbusinessgame/Game.vue')
  },
  {
    path: '/funbusinessgame/new',
    name: 'funbusinessgame-new-game',
    component: () =>
      import(/* webpackChunkName: 'funbusinessgame' */ '@/views/funbusinessgame/NewGame.vue')
  },
  {
    path: '/funbusinessgame/new-without-register',
    name: 'funbusinessgame-new-game-without-register',
    component: () =>
      import(
        /* webpackChunkName: 'funbusinessgame' */ '@/views/funbusinessgame/NewGameWithoutRegister.vue'
      )
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: 'auth' */ '@/views/Register.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'auth' */ '@/views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: 'auth' */ '@/views/ForgotPassword.vue')
  },
  {
    path: '/checkout-with-register',
    name: 'checkout-with-register',
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/CheckoutWithRegister.vue')
  },
  {
    path: '/free-checkout',
    name: 'free-checkout',
    component: () =>
      import(/* webpackChunkName: 'checkout' */ '@/views/FreeCheckoutWithRegister.vue')
  },
  // {
  //   path: '/checkout',
  //   name: 'checkout',
  //   component: () => import(/* webpackChunkName: 'checkout' */ '@/views/Checkout.vue')
  // },
  {
    path: '/checkout-cancel',
    name: 'checkout-cancel',
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/CheckoutFail.vue')
  },
  {
    path: '/checkout-success',
    name: 'checkout-success',
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/CheckoutSuccess.vue')
  },
  {
    path: '/superuser-panel',
    name: 'superuser-panel',
    component: () => import(/* webpackChunkName: 'management' */ '@/views/SuperuserPanel.vue'),
    children: [
      {
        path: '',
        name: 'SuperUserCompaniesList',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserCompaniesList.vue'
          )
      },
      {
        path: ':id',
        name: 'SuperUserCompanyView',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserCompanyView.vue'
          )
      },
      {
        path: 'referrals',
        name: 'SuperUserIndividualReferrals',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserIndividualReferrals.vue'
          )
      },
      {
        path: 'discounts',
        name: 'SuperUserDiscounts',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserDiscounts.vue'
          )
      },
      {
        path: 'funbusinessgame-boards',
        name: 'SuperUserFunBusinessGameBoards',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFunBusinessGameBoards.vue'
          )
      },
      {
        path: 'funbusinessgame-chance',
        name: 'SuperUserFunBusinessGameChance',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFunBusinessGameChance.vue'
          )
      },
      {
        path: 'funteambidding-custom-questions',
        name: 'SuperUserFunTeamBiddingQuestions',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFunTeamBiddingQuestions.vue'
          )
      },
      {
        path: 'funicebreaker-custom-questions',
        name: 'SuperUserFunIceBreakerQuestions',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFunIceBreakerQuestions.vue'
          )
      },
      {
        path: 'create-company',
        name: 'SuperUserCreateCompanyManually',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserCreateCompanyManually.vue'
          )
      },
      {
        path: 'feedback',
        name: 'SuperUserFeedback',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFeedback.vue'
          )
      },
      {
        path: 'feedback-admin-panel',
        name: 'SuperUserFeedbackFromAdminPanel',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserFeedbackFromAdminPanel.vue'
          )
      },
      {
        path: 'started-games',
        name: 'SuperUserStartedGames',
        component: () =>
          import(
            /* webpackChunkName: 'management' */ '@/components/superuser/SuperUserStartedGames.vue'
          )
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: 'admin-panel' */ '@/views/AdminPanel.vue'),
    children: [
      {
        path: '',
        name: 'admin-dashboard',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelDashboard.vue'
          )
      },
      {
        path: 'branding',
        name: 'admin-branding',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelBranding.vue'
          )
      },
      {
        path: 'users',
        name: 'admin-users',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelUsers.vue'
          )
      },
      {
        path: 'referral-program',
        name: 'admin-referral-program',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelReferralProgram.vue'
          )
      },
      {
        path: 'help-and-feedback',
        name: 'admin-help-instructions',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelHelp.vue'
          )
      },
      {
        path: 'funbusinessgame',
        name: 'funbusinessgame',
        redirect: () => '/dashboard/funbusinessgame/games'
      },
      {
        path: 'funbusinessgame/board-config',
        name: 'funbusinessgame-board-config',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelBoardConfig.vue'
          )
      },
      {
        path: 'funbusinessgame/card-upgrade',
        name: 'funbusinessgame-card-upgrade',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelBoardCardUpgrade.vue'
          )
      },
      {
        path: 'funbusinessgame/chance-config',
        name: 'funbusinessgame-chance-config',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/AdminPanelChanceConfig.vue'
          )
      },
      {
        path: 'funbusinessgame/games',
        name: 'funbusinessgame-games',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funbusinessgame/AdminPanelBusinessGameActiveGames.vue'
          )
      },
      {
        path: 'funteambidding',
        name: 'funteambidding',
        redirect: () => '/dashboard/funteambidding/games'
      },
      {
        path: 'funteambidding/games',
        name: 'funteambidding-games',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funteambidding/AdminPanelFunTeamBiddingActiveGames.vue'
          )
      },
      {
        path: 'funteambidding/settings',
        name: 'funteambidding-settings',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funteambidding/AdminPanelFunTeamBiddingSettings.vue'
          )
      },
      {
        path: 'funteambidding/custom-questions',
        name: 'funteambidding-custom-questions',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funteambidding/AdminPanelFunTeamBiddingCustomQuestions.vue'
          )
      },
      {
        path: 'funteamclues',
        name: 'funteamclues',
        redirect: () => '/dashboard/funteamclues/games'
      },
      {
        path: 'funteamclues/games',
        name: 'funteamclues-games',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funteamclues/AdminPanelFunTeamCluesActiveGames.vue'
          )
      },
      {
        path: 'funicebreaker',
        name: 'funicebreaker',
        redirect: () => '/dashboard/funicebreaker/games'
      },
      {
        path: 'funicebreaker/games',
        name: 'funicebreaker-games',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funicebreaker/AdminPanelFunIceBreakerActiveGames.vue'
          )
      },
      {
        path: 'funicebreaker/custom-questions',
        name: 'funicebreaker-custom-questions',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funicebreaker/AdminPanelFunIceBreakerCustomQuestions.vue'
          )
      },
      // {
      //   path: 'funicebreaker/leaderboard',
      //   name: 'funicebreaker-leaderboard',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funicebreaker/AdminPanelFunIceBreakerLeaderboard.vue'
      //     )
      // },
      {
        path: 'funicebreaker/settings',
        name: 'funicebreaker-settings',
        component: () =>
          import(
            /* webpackChunkName: 'admin-panel' */ '@/components/admin-panel/funicebreaker/AdminPanelFunIceBreakerSettings.vue'
          )
      }
    ]
  },
  {
    path: '/blog/5-lessons-how-to-build-relationships-in-remote-teams',
    name: 'blog-5-lessons',
    component: () =>
      import(
        /* webpackChunkName: 'blog' */ '@/views/blog/5-lessons-how-to-build-relationships-in-remote-teams.vue'
      )
  },
  {
    path: '/blog/5-wskazowek-jak-budowac-relacje-w-zespole-zdalnie',
    name: 'blog-5-wskazowek',
    component: () =>
      import(
        /* webpackChunkName: 'blog' */ '@/views/blog/5-wskazowek-jak-budowac-relacje-w-zespole-zdalnie.vue'
      )
  },
  {
    path: '/blog/remote-fun-customizable-team-building',
    name: 'blog-remote-fun-customizable',
    component: () =>
      import(
        /* webpackChunkName: 'blog' */ '@/views/blog/remote-fun-customizable-team-building.vue'
      )
  },
  {
    path: '/blog/remote-fun-zdalny-teambuilding-dla-twojego-zespolu',
    name: 'blog-remote-fun-zdalny',
    component: () =>
      import(
        /* webpackChunkName: 'blog' */ '@/views/blog/remote-fun-zdalny-teambuilding-dla-twojego-zespolu.vue'
      )
  },
  {
    path: '/mailing',
    name: 'mailing',
    component: () => import(/* webpackChunkName: 'mailing' */ '@/views/Mailing.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return '/login';
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
