<template>
  <div class="logo-spinner flex flex-column items-center justify-center">
    <div class="content flex flex-column items-center justify-center">
      <img :src="require(`@/assets/logos/remote-fun/logo-dark-tile.svg`)" alt="logo" class="mb4" />
      <ProgressSpinner
        class="p-progress-spinner--primary p-progress-spinner--panel"
        style="width: 50px; height: 50px"
        stroke-width="5"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LogoSpinner'
  };
</script>

<style scoped lang="scss">
  .logo-spinner {
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    position: relative;

    .content {
      position: relative;
      transform: translateY(-50%);
    }

    img {
      width: 120px;
    }
  }
</style>
