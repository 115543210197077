import {
  Card,
  CardTypes,
  ChanceCard,
  Game,
  Player,
  PossibleActionsOnCards,
  PossibleActionsOnCardsWithDecision,
  TemplateItem,
  CardUpgradeConfig
} from '@/interfaces/businessgame';
import { defaultAvatars } from '@/constants/avatars';

export const CARD_TYPES: CardTypes = {
  TEAM: 'TEAM',
  OFFICE: 'OFFICE',
  ZUS: 'ZUS',
  CHANCE: 'CHANCE',
  CORNER: 'CORNER',
  INDIVIDUAL: 'INDIVIDUAL'
};

export const BUYABLE_CARDS: string[] = [CARD_TYPES.TEAM, CARD_TYPES.OFFICE, CARD_TYPES.INDIVIDUAL];

export const POSSIBLE_ACTIONS_ON_CARDS_WITH_DECISION: PossibleActionsOnCardsWithDecision = {
  BUY: 'BUY',
  PASS: 'PASS',
  AUCTION: 'AUCTION',
  ASK_TO_BUY: 'ASK_TO_BUY',
  SELL: 'SELL',
  GET_CATEGORY_ONE: 'GET_CATEGORY_ONE',
  PROMOTE_CATEGORY_ONE: 'PROMOTE_CATEGORY_ONE',
  LET_GO_CATEGORY_ONE: 'LET_GO_CATEGORY_ONE',
  GET_CATEGORY_TWO: 'GET_CATEGORY_TWO',
  PROMOTE_CATEGORY_TWO: 'PROMOTE_CATEGORY_TWO',
  LET_GO_CATEGORY_TWO: 'LET_GO_CATEGORY_TWO',
  GET_CATEGORY_THREE: 'GET_CATEGORY_THREE',
  PROMOTE_CATEGORY_THREE: 'PROMOTE_CATEGORY_THREE',
  LET_GO_CATEGORY_THREE: 'LET_GO_CATEGORY_THREE',
  GET_CATEGORY_FOUR: 'GET_CATEGORY_FOUR',
  LET_GO_CATEGORY_FOUR: 'LET_GO_CATEGORY_FOUR'
};

export const POSSIBLE_ACTIONS_ON_CARDS: PossibleActionsOnCards = {
  ...POSSIBLE_ACTIONS_ON_CARDS_WITH_DECISION,
  TAKE_CHANCE: 'TAKE_CHANCE',
  PAY_ZUS: 'PAY_ZUS',
  GO_TO_PRISON: 'GO_TO_PRISON',
  TAKE_BONUS_MONEY: 'TAKE_BONUS_MONEY'
};

export const BOARD_CONFIGS_TEMPLATES: TemplateItem[] = [
  {
    value: 'default',
    label: 'IT / Softwarehouse'
  },
  // {
  //   value: 'automobile',
  //   label: 'Cars / Automobile'
  // },
  {
    value: 'startup',
    label: 'Startups'
  },
  {
    value: 'cuisine',
    label: 'Restaurants'
  },
  {
    value: 'fintech',
    label: 'Fintech & Banking'
  },
  {
    value: 'law',
    label: 'Law'
  }
  // {
  //   value: 'future',
  //   label: 'Futuristic'
  // }
];

export const CHANCE_CONFIGS_TEMPLATES: TemplateItem[] = [
  {
    value: 'default',
    label: 'IT / Softwarehouse'
  },
  // {
  //   value: 'automobile',
  //   label: 'Cars / Automobile'
  // },
  {
    value: 'startup',
    label: 'Startups'
  },
  {
    value: 'cuisine',
    label: 'Restaurants'
  },
  {
    value: 'fintech',
    label: 'Fintech & Banking'
  },
  {
    value: 'law',
    label: 'Law'
  }
  // {
  //   value: 'future',
  //   label: 'Futuristic'
  // }
];

export const CARDS_BOTTOM: Card[] = [
  {
    id: 1,
    type: CARD_TYPES.CORNER,
    cannotBeOwned: true,
    title: 'PAYROLL'
  },
  {
    id: 2,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 3,
    type: CARD_TYPES.TEAM,
    price: 100,
    baseCharge: 10,
    owner: '',
    title: 'Office',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 50,
        sell: 35,
        charge: 18,
        promote: 20
      },
      categoryTwo: {
        buy: 100,
        sell: 70,
        charge: 30,
        promote: 25
      },
      categoryThree: {
        buy: 150,
        sell: 110,
        charge: 45,
        promote: 30
      },
      categoryFour: {
        buy: 200,
        sell: 140,
        charge: 60
      }
    },
    color: 'pink'
  },
  {
    id: 4,
    type: CARD_TYPES.TEAM,
    price: 100,
    baseCharge: 10,
    owner: '',
    title: 'QA',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 50,
        sell: 35,
        charge: 18,
        promote: 20
      },
      categoryTwo: {
        buy: 100,
        sell: 70,
        charge: 30,
        promote: 25
      },
      categoryThree: {
        buy: 150,
        sell: 110,
        charge: 45,
        promote: 30
      },
      categoryFour: {
        buy: 200,
        sell: 140,
        charge: 60
      }
    },
    color: 'brown'
  },
  {
    id: 5,
    type: CARD_TYPES.ZUS,
    title: 'test',
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.PAY_ZUS
  },
  {
    id: 6,
    type: CARD_TYPES.OFFICE,
    price: 150,
    baseCharge: 25,
    owner: '',
    title: 'Poznań',
    image: 'poznan.jpg'
  },
  {
    id: 7,
    type: CARD_TYPES.TEAM,
    price: 120,
    baseCharge: 10,
    owner: '',
    title: 'Devops',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 50,
        sell: 35,
        charge: 18,
        promote: 20
      },
      categoryTwo: {
        buy: 100,
        sell: 70,
        charge: 30,
        promote: 25
      },
      categoryThree: {
        buy: 150,
        sell: 110,
        charge: 45,
        promote: 30
      },
      categoryFour: {
        buy: 200,
        sell: 140,
        charge: 60
      }
    },
    color: '#222'
  },
  {
    id: 8,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 9,
    type: CARD_TYPES.TEAM,
    price: 120,
    baseCharge: 12,
    owner: '',
    title: 'Inbound sales',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 50,
        sell: 35,
        charge: 18,
        promote: 20
      },
      categoryTwo: {
        buy: 100,
        sell: 70,
        charge: 30,
        promote: 25
      },
      categoryThree: {
        buy: 150,
        sell: 110,
        charge: 45,
        promote: 30
      },
      categoryFour: {
        buy: 200,
        sell: 140,
        charge: 60
      }
    },
    color: 'blue'
  },
  {
    id: 10,
    type: CARD_TYPES.TEAM,
    price: 120,
    baseCharge: 12,
    owner: '',
    title: 'Outbound Sales',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 50,
        sell: 35,
        charge: 18,
        promote: 20
      },
      categoryTwo: {
        buy: 100,
        sell: 70,
        charge: 30,
        promote: 25
      },
      categoryThree: {
        buy: 150,
        sell: 110,
        charge: 45,
        promote: 30
      },
      categoryFour: {
        buy: 200,
        sell: 140,
        charge: 60
      }
    },
    color: 'blue'
  }
];

export const CARDS_LEFT: Card[] = [
  {
    id: 11,
    type: CARD_TYPES.CORNER,
    cannotBeOwned: true,
    title: 'BENCH'
  },
  {
    id: 12,
    type: CARD_TYPES.TEAM,
    price: 140,
    baseCharge: 14,
    owner: '',
    title: 'Finance',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 60,
        sell: 40,
        charge: 20,
        promote: 25
      },
      categoryTwo: {
        buy: 120,
        sell: 80,
        charge: 37,
        promote: 30
      },
      categoryThree: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 35
      },
      categoryFour: {
        buy: 240,
        sell: 160,
        charge: 75
      }
    },
    color: 'darkgreen'
  },
  {
    id: 13,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 14,
    type: CARD_TYPES.TEAM,
    price: 140,
    baseCharge: 14,
    owner: '',
    title: 'Marketing',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 60,
        sell: 40,
        charge: 20,
        promote: 25
      },
      categoryTwo: {
        buy: 120,
        sell: 80,
        charge: 37,
        promote: 30
      },
      categoryThree: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 35
      },
      categoryFour: {
        buy: 240,
        sell: 160,
        charge: 75
      }
    },
    color: 'magenta'
  },
  {
    id: 15,
    type: CARD_TYPES.TEAM,
    price: 140,
    baseCharge: 14,
    owner: '',
    title: 'Social Media',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 60,
        sell: 40,
        charge: 20,
        promote: 25
      },
      categoryTwo: {
        buy: 120,
        sell: 80,
        charge: 37,
        promote: 30
      },
      categoryThree: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 35
      },
      categoryFour: {
        buy: 240,
        sell: 160,
        charge: 75
      }
    },
    color: 'magenta'
  },
  {
    id: 16,
    type: CARD_TYPES.OFFICE,
    price: 150,
    baseCharge: 25,
    owner: '',
    title: 'Wrocław',
    image: 'wroclaw.jpeg'
  },
  {
    id: 17,
    type: CARD_TYPES.TEAM,
    price: 160,
    baseCharge: 16,
    owner: '',
    title: 'Recruitment',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 60,
        sell: 40,
        charge: 20,
        promote: 25
      },
      categoryTwo: {
        buy: 120,
        sell: 80,
        charge: 37,
        promote: 30
      },
      categoryThree: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 35
      },
      categoryFour: {
        buy: 240,
        sell: 160,
        charge: 75
      }
    },
    color: 'orange'
  },
  {
    id: 18,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 19,
    type: CARD_TYPES.TEAM,
    price: 170,
    baseCharge: 17,
    owner: '',
    title: 'HR',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 60,
        sell: 40,
        charge: 20,
        promote: 25
      },
      categoryTwo: {
        buy: 120,
        sell: 80,
        charge: 37,
        promote: 30
      },
      categoryThree: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 35
      },
      categoryFour: {
        buy: 240,
        sell: 160,
        charge: 75
      }
    },
    color: 'orange'
  },
  {
    id: 20,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  }
];

export const CARDS_TOP: Card[] = [
  {
    id: 21,
    type: CARD_TYPES.CORNER,
    cannotBeOwned: true,
    title: 'BONUS',
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_BONUS_MONEY
  },
  {
    id: 22,
    type: CARD_TYPES.TEAM,
    price: 180,
    baseCharge: 18,
    owner: '',
    title: 'Project managers',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: '#f14668'
  },
  {
    id: 23,
    type: CARD_TYPES.TEAM,
    price: 180,
    baseCharge: 18,
    owner: '',
    title: 'Product owners',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: '#f14668'
  },
  {
    id: 24,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 25,
    type: CARD_TYPES.TEAM,
    price: 200,
    baseCharge: 20,
    owner: '',
    title: 'Android',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: 'purple'
  },
  {
    id: 26,
    type: CARD_TYPES.OFFICE,
    price: 150,
    baseCharge: 25,
    owner: '',
    title: 'Warsaw',
    image: 'astoria.jpg'
  },
  {
    id: 27,
    type: CARD_TYPES.TEAM,
    price: 200,
    baseCharge: 20,
    owner: '',
    title: 'iOS',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: 'purple'
  },
  {
    id: 28,
    type: CARD_TYPES.TEAM,
    price: 220,
    baseCharge: 22,
    owner: '',
    title: 'UI/UX',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: 'yellow'
  },
  {
    id: 29,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 30,
    type: CARD_TYPES.TEAM,
    price: 220,
    baseCharge: 22,
    owner: '',
    title: '3D Design',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 75,
        sell: 50,
        charge: 25,
        promote: 30
      },
      categoryTwo: {
        buy: 150,
        sell: 100,
        charge: 48,
        promote: 35
      },
      categoryThree: {
        buy: 225,
        sell: 150,
        charge: 70,
        promote: 45
      },
      categoryFour: {
        buy: 300,
        sell: 200,
        charge: 90
      }
    },
    color: 'yellow'
  }
];

export const CARDS_RIGHT: Card[] = [
  {
    id: 31,
    type: CARD_TYPES.CORNER,
    cannotBeOwned: true,
    title: 'GO TO BENCH',
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.GO_TO_PRISON
  },
  {
    id: 32,
    type: CARD_TYPES.TEAM,
    price: 230,
    baseCharge: 23,
    owner: '',
    title: 'Machine Learning',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 90,
        sell: 60,
        charge: 30,
        promote: 25
      },
      categoryTwo: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 30
      },
      categoryThree: {
        buy: 270,
        sell: 180,
        charge: 85,
        promote: 40
      },
      categoryFour: {
        buy: 360,
        sell: 270,
        charge: 115
      }
    },
    color: '#48c774'
  },
  {
    id: 33,
    type: CARD_TYPES.TEAM,
    price: 250,
    baseCharge: 25,
    owner: '',
    title: 'Backend',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 90,
        sell: 60,
        charge: 30,
        promote: 25
      },
      categoryTwo: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 30
      },
      categoryThree: {
        buy: 270,
        sell: 180,
        charge: 85,
        promote: 40
      },
      categoryFour: {
        buy: 360,
        sell: 270,
        charge: 115
      }
    },
    color: '#48c774'
  },
  {
    id: 34,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 35,
    type: CARD_TYPES.TEAM,
    price: 250,
    baseCharge: 25,
    owner: '',
    title: 'Frontend',
    team: {
      categoryOne: 0,
      categoryTwo: 0,
      categoryThree: 0,
      categoryFour: 0
    },
    prices: {
      categoryOne: {
        buy: 90,
        sell: 60,
        charge: 30,
        promote: 25
      },
      categoryTwo: {
        buy: 180,
        sell: 120,
        charge: 55,
        promote: 30
      },
      categoryThree: {
        buy: 270,
        sell: 180,
        charge: 85,
        promote: 40
      },
      categoryFour: {
        buy: 360,
        sell: 270,
        charge: 115
      }
    },
    color: '#48c774'
  },
  {
    id: 36,
    type: CARD_TYPES.OFFICE,
    price: 150,
    baseCharge: 25,
    owner: '',
    title: 'New Office',
    image: 'finlandzka.jpg'
  },
  {
    id: 37,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 38,
    type: CARD_TYPES.INDIVIDUAL,
    price: 300,
    owner: '',
    title: 'CTO',
    color: '#202A57',
    baseCharge: 100
  },
  {
    id: 39,
    type: CARD_TYPES.CHANCE,
    cannotBeOwned: true,
    automaticAction: POSSIBLE_ACTIONS_ON_CARDS.TAKE_CHANCE
  },
  {
    id: 40,
    type: CARD_TYPES.INDIVIDUAL,
    price: 300,
    owner: '',
    title: 'CEO',
    color: '#202A57',
    baseCharge: 100
  }
];

export const CHANCE_CARDS: ChanceCard[] = [
  {
    id: '1',
    type: 'chance',
    descriptionEn:
      'You referred a person that joined the company. Education budget does not exist in the game :P Nothing happens.',
    descriptionPl:
      'You referred a person that joined the company. Education budget does not exist in the game :P Nothing happens.'
  },
  {
    id: '2',
    type: 'chance',
    descriptionEn:
      'You did not log hours at the end of the month. JIRA needs to be opened. You pay 50 for :przedszkole:',
    descriptionPl:
      'You did not log hours at the end of the month. JIRA needs to be opened. You pay 50 for :przedszkole:',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 50
  },
  {
    id: '3',
    type: 'chance',
    descriptionEn:
      'You made a mistake on an invoice. HR is not delighted. You pay 80 for being absent-minded.',
    descriptionPl:
      'You made a mistake on an invoice. HR is not delighted. You pay 80 for being absent-minded.',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 80
  },
  {
    id: '4',
    type: 'chance',
    descriptionEn:
      'You found 50 on your way to the office. It is nice to sometimes go to the office.',
    descriptionPl:
      'You found 50 on your way to the office. It is nice to sometimes go to the office.',
    method: 'addMoneyToCurrentPlayer',
    payload: 50
  },
  {
    id: '5',
    type: 'chance',
    descriptionEn: 'You received good half-year feedback. You can roll again.',
    descriptionPl: 'You received good half-year feedback. You can roll again.',
    method: 'addRoundToCurrentPlayer',
    payload: 1
  },
  {
    id: '6',
    type: 'chance',
    descriptionEn:
      'You got an invitation for party with a team from another city. It costs you 50 to get there, but it was worth it.',
    descriptionPl:
      'You got an invitation for party with a team from another city. It costs you 50 to get there, but it was worth it.',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 50
  },
  {
    id: '7',
    type: 'chance',
    descriptionEn:
      'You ordered something to come to Finlandzka. You need to go there now and pay 40 for Uber.',
    descriptionPl:
      'You ordered something to come to Finlandzka. You need to go there now and pay 40 for Uber.',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 40
  },
  {
    id: '8',
    type: 'chance',
    descriptionEn: 'You will be doing a project in jQuery. Pay 50 up front for a psychiatrist.',
    descriptionPl: 'You will be doing a project in jQuery. Pay 50 up front for a psychiatrist.',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 50
  },
  {
    id: '9',
    type: 'chance',
    descriptionEn:
      'You found 20 on your way to the office. Oh wait... your home is your office and you were coming back from toilet. Nothing happens.',
    descriptionPl:
      'You found 20 on your way to the office. Oh wait... your home is your office and you were coming back from toilet. Nothing happens.'
  },
  {
    id: '10',
    type: 'chance',
    descriptionEn:
      'You saw your superior completely drunk. He/she gives you 50 to keep you quiet about this.',
    descriptionPl:
      'You saw your superior completely drunk. He/she gives you 50 to keep you quiet about this.',
    method: 'addMoneyToCurrentPlayer',
    payload: 50
  },
  {
    id: '11',
    type: 'chance',
    descriptionEn:
      'During remote company party you spilled some beer on the laptop. Pay 50 for repair.',
    descriptionPl:
      'During remote company party you spilled some beer on the laptop. Pay 50 for repair.',
    method: 'removeMoneyFromCurrentPlayer',
    payload: 50
  },
  {
    id: '12',
    type: 'chance',
    descriptionEn: 'You got praised on #praise! 100 coming right up to your account.',
    descriptionPl: 'You got praised on #praise! 100 coming right up to your account.',
    method: 'addMoneyToCurrentPlayer',
    payload: 100
  },
  {
    id: '13',
    type: 'chance',
    descriptionEn: 'You got praised on #praise! 50 coming right up to your account.',
    descriptionPl: 'You got praised on #praise! 50 coming right up to your account.',
    method: 'addMoneyToCurrentPlayer',
    payload: 50
  },
  {
    id: '14',
    type: 'chance',
    descriptionEn: 'You did a great job last half a year. Congratulations. You get a raise of 20.',
    descriptionPl: 'You did a great job last half a year. Congratulations. You get a raise of 20.',
    method: 'addToPayroll',
    payload: 20
  },
  {
    id: '15',
    type: 'chance',
    descriptionEn: 'You did a great job last half a year. Congratulations. You get a raise of 30.',
    descriptionPl: 'You did a great job last half a year. Congratulations. You get a raise of 30.',
    method: 'addToPayroll',
    payload: 30
  },
  {
    id: '16',
    type: 'chance',
    descriptionEn:
      'Last half of the year was bad for you in every way. So why not get a pay cut of 20 as well?',
    descriptionPl:
      'Last half of the year was bad for you in every way. So why not get a pay cut of 20 as well?',
    method: 'removeFromPayroll',
    payload: 20
  },
  {
    id: '17',
    type: 'chance',
    descriptionEn: 'You get a yellow card and pay cut of 30.',
    descriptionPl: 'You get a yellow card and pay cut of 30.',
    method: 'removeFromPayroll',
    payload: 30
  },
  {
    id: '21',
    type: 'chance',
    descriptionEn:
      'You get a 100 bonus for future development of your teams if you have max 5 teams in your portfolio',
    descriptionPl:
      'You get a 100 bonus for future development of your teams if you have max 5 teams in your portfolio',
    method: 'addMoneyToCurrentPlayerIfConditions',
    payload: {
      type: 'cardsMax',
      value: 5,
      money: 100
    }
  },
  {
    id: '22',
    type: 'chance',
    descriptionEn:
      'You get a 50 bonus for future development of your teams if you have max 10 teams in your portfolio',
    descriptionPl:
      'You get a 50 bonus for future development of your teams if you have max 10 teams in your portfolio',
    method: 'addMoneyToCurrentPlayerIfConditions',
    payload: {
      type: 'cardsMax',
      value: 10,
      money: 50
    }
  },
  {
    id: '23',
    type: 'chance',
    descriptionEn:
      'You went to cool workshops about team building and learnt a lot. Every junior in your teams is promoted to mid 💪💪',
    descriptionPl:
      'You went to cool workshops about team building and learnt a lot. Every junior in your teams is promoted to mid 💪💪',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryOne',
      to: 'categoryTwo',
      max: null
    }
  },
  {
    id: '24',
    type: 'chance',
    descriptionEn:
      'You went to cool workshops about team building and learnt a lot. Every mid in your teams is promoted to senior 💪💪',
    descriptionPl:
      'You went to cool workshops about team building and learnt a lot. Every mid in your teams is promoted to senior 💪💪',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryTwo',
      to: 'categoryThree',
      max: null
    }
  },
  {
    id: '25',
    type: 'chance',
    descriptionEn:
      'You went to cool workshops about team building and learnt something. Up to one junior in your teams is promoted to mid 💪💪',
    descriptionPl:
      'You went to cool workshops about team building and learnt something. Up to one junior in your teams is promoted to mid 💪💪',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryOne',
      to: 'categoryTwo',
      max: 1
    }
  },
  {
    id: '26',
    type: 'chance',
    descriptionEn:
      'You went to cool workshops about team building and learnt something. Up to one mid in your teams is promoted to senior 💪💪',
    descriptionPl:
      'You went to cool workshops about team building and learnt something. Up to one mid in your teams is promoted to senior 💪💪',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryTwo',
      to: 'categoryThree',
      max: 1
    }
  },
  {
    id: '27',
    type: 'chance',
    descriptionEn:
      'There are not enough projects or needs for people in your team. Financial situation gets worse and you have to downgrade all mids in your teams to juniors.',
    descriptionPl:
      'There are not enough projects or needs for people in your team. Financial situation gets worse and you have to downgrade all mids in your teams to juniors.',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryTwo',
      to: 'categoryOne',
      max: null
    }
  },
  {
    id: '28',
    type: 'chance',
    descriptionEn:
      'There are not enough projects or needs for people in your team. Financial situation gets worse and you have to let go all juniors in your teams.',
    descriptionPl:
      'There are not enough projects or needs for people in your team. Financial situation gets worse and you have to let go all juniors in your teams.',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryOne',
      to: '',
      max: null
    }
  },
  {
    id: '29',
    type: 'chance',
    descriptionEn:
      'Financial and project situation gets a bit worse. Up to one mid from every team that you own is downgraded to junior.',
    descriptionPl:
      'Financial and project situation gets a bit worse. Up to one mid from every team that you own is downgraded to junior.',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryTwo',
      to: 'categoryOne',
      max: 1
    }
  },
  {
    id: '30',
    type: 'chance',
    descriptionEn:
      'Financial and project situation gets a bit worse. Up to one senior from every team that you own is downgraded to mid.',
    descriptionPl:
      'Financial and project situation gets a bit worse. Up to one senior from every team that you own is downgraded to mid.',
    method: 'changeTeamMembers',
    payload: {
      from: 'categoryThree',
      to: 'categoryTwo',
      max: 1
    }
  }
];

export const emptyGame: Game = {
  id: '',
  log: [],
  previewedCard: {},
  boardConfig: [],
  chanceCards: [],
  initialChanceCards: [],
  activeInsideCard: null,
  auction: null,
  avatars: defaultAvatars,
  duration: 0,
  diceConfig: {
    one: 0,
    two: 0,
    rolled: false
  },
  actionActive: false,
  activePlayerIndex: 0,
  roundsLeftForCurrentPlayer: 0,
  activeCard: {},
  players: [],
  gameStarted: false,
  gameEnded: false,
  gameCancelled: false,
  endResults: [],
  bonusMoney: 0,
  buyOffer: null,
  playerMoneyAtStart: 3000,
  upgradeConfig: {
    categoryOne: {
      en: '',
      pl: ''
    },
    categoryTwo: {
      en: '',
      pl: ''
    },
    categoryThree: {
      en: '',
      pl: ''
    },
    categoryFour: {
      en: '',
      pl: ''
    }
  },
  settings: {
    audioOnNextPlayer: true,
    gameSounds: true,
    gameFinishTime: null
  },
  gameCreator: null
};

export const emptyPlayer: Player = {
  id: '',
  name: '',
  boardField: 0,
  owned: [],
  waitRounds: 0,
  avatar: {
    pack: '',
    name: ''
  },
  actionsAvailable: 1,
  payroll: 200,
  roundsLeft: 1,
  money: 0,
  email: ''
};

export const cardUpgradeConfig: CardUpgradeConfig = {
  categoryOne: {
    en: 'Juniors',
    pl: 'Juniorzy'
  },
  categoryTwo: {
    en: 'Mids',
    pl: 'Midzi'
  },
  categoryThree: {
    en: 'Seniors',
    pl: 'Seniorzy'
  },
  categoryFour: {
    en: 'Leads',
    pl: 'Liderzy'
  }
};
