import { FunIceBreakerGame, FunIceBreakerPlayer } from '@/interfaces/funicebreaker';
import { defaultAvatars } from '@/constants/avatars';

export const FUN_ICE_BREAKER_PHASES = {
  QUESTION: {
    value: 'QUESTION',
    step: 1
  },
  MATCHING: {
    value: 'MATCHING',
    step: 2
  },
  STORY: {
    value: 'STORY',
    step: 3
  }
};

export const emptyFunIceBreakerPlayer: FunIceBreakerPlayer = {
  id: '',
  name: '',
  nickname: '',
  job: '',
  email: '',
  admin: false,
  points: 0,
  pointsInPreviousRound: 0,
  avatar: {
    pack: '',
    name: ''
  }
};

export const emptyFunIceBreakerSettings = {
  defaultNoOfQuestions: 5
};

export const emptyFunIceBreakerGame: FunIceBreakerGame = {
  id: '',
  createdOn: 0,
  players: [],
  settings: { ...emptyFunIceBreakerSettings },
  gameStarted: false,
  gameEnded: false,
  gameCancelled: false,
  endResults: {},
  questions: [],
  activeQuestion: null,
  playersMatching: [],
  activeGuessingOptions: [],
  playersMatchingStartTime: 0,
  phase: 'WELCOME',
  noOfQuestionsLeft: 0,
  matchingLimit: null,
  initialNoOfQuestions: 0,
  avatars: defaultAvatars,
  storyPlayerIndex: 0,
  pointsInPreviousRound: [],
  gameCreator: null
};
