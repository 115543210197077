export const GAME_NAMES = {
  FUN_BUSINESS_GAME: 'FUN_BUSINESS_GAME',
  FUN_TEAM_BIDDING: 'FUN_TEAM_BIDDING',
  FUN_ICE_BREAKER: 'FUN_ICE_BREAKER',
  FUN_TEAM_CLUES: 'FUN_TEAM_CLUES'
};

export const GAME_NAMES_HUMANIZED = {
  FUN_BUSINESS_GAME: 'FunBusinessGame',
  FUN_TEAM_BIDDING: 'FunTeamBidding',
  FUN_ICE_BREAKER: 'FunIceBreaker',
  FUN_TEAM_CLUES: 'FunTeamClues'
};
import { format } from 'date-fns';
import { calculateCardCharge } from '@/helpers/players-helpers';
import { Card, Game, Player, LogItem } from '@/interfaces/businessgame';

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const capitalizeAndRemoveLastLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1, -1);
};

export const buy = (
  game: Game,
  boughtCard: Card,
  playerBuying: Player | null,
  setPrice: number | null,
  translation: any
) => {
  const player = playerBuying
    ? { ...playerBuying }
    : {
        ...game.players[game.activePlayerIndex]
      };

  player.owned.push({
    ...boughtCard,
    owner: player.id
  });

  const price = setPrice ? setPrice : boughtCard.price;
  player.money -= price || 0;
  boughtCard.owner = player.id;

  const playerIndex = game.players.findIndex(p => p.id === player.id);

  return {
    players: [
      ...game.players.slice(0, playerIndex),
      player,
      ...game.players.slice(playerIndex + 1)
    ],
    log: [
      ...game.log,
      {
        date: format(new Date(), 'HH:mm'),
        name: translation('log.buy', { card: boughtCard.title }),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    boardConfig: [
      ...game.boardConfig.slice(0, boughtCard.id - 1),
      boughtCard,
      ...game.boardConfig.slice(boughtCard.id)
    ]
  };
};

export const sell = (game: Game, sellingCard: Card, translation: any) => {
  const cardOwner = game.players.find((p: Player) => p.id === sellingCard.owner);

  const teamValue = (sellingCard.price || 0) * 0.5;

  if (sellingCard.prices && cardOwner) {
    const teamMembersValue = sellingCard.team
      ? sellingCard.team.categoryOne * sellingCard.prices.categoryOne.sell +
        sellingCard.team.categoryTwo * sellingCard.prices.categoryTwo.sell +
        sellingCard.team.categoryThree * sellingCard.prices.categoryThree.sell +
        sellingCard.team.categoryFour * sellingCard.prices.categoryFour.sell
      : 0;

    const boardConfig = [...game.boardConfig];

    cardOwner.owned = cardOwner.owned.filter(card => card.id !== sellingCard.id);
    cardOwner.money = cardOwner.money + teamValue + teamMembersValue;

    boardConfig[sellingCard.id - 1] = {
      ...boardConfig[sellingCard.id - 1],
      owner: ''
    };

    return {
      players: [
        ...game.players.slice(0, game.activePlayerIndex),
        cardOwner,
        ...game.players.slice(game.activePlayerIndex + 1)
      ],
      log: [
        ...game.log,
        {
          date: format(new Date(), 'HH:mm'),
          name: translation('log.sell', { card: sellingCard.title }),
          player: {
            id: cardOwner.id,
            name: cardOwner.name,
            avatar: cardOwner.avatar
          }
        }
      ],
      boardConfig
    };
  }
};

export const askToBuy = (game: Game, previewedCard: Card, translation: any) => {
  const askingPlayer = { ...game.players[game.activePlayerIndex] };
  const currentOwner = game.players.find(p => p.id === previewedCard.owner);

  const offer = prompt(
    translation('log.askToBuyHowMuch', {
      name: currentOwner?.name || '',
      card: previewedCard.title
    })
  );

  if (isNaN(Number(offer))) {
    alert('Wrong number');
  }

  if (Number(offer) > askingPlayer.money) {
    alert(translation('log.notEnoughMoney'));
    return;
  }

  return {
    buyOffer: {
      askingPlayer,
      currentOwner,
      offer: Number(offer),
      card: previewedCard,
      consent: false
    }
  };
};

export const agreeToSell = (game: Game) => {
  // GENERAL
  let updatedPlayers = game.players;

  // BOARD
  const updatedBoardConfig = [
    ...game.boardConfig.slice(0, game.buyOffer.card.id - 1),
    game.buyOffer.card,
    ...game.boardConfig.slice(game.buyOffer.card.id)
  ];

  // BUY
  const updatedBuyer = {
    ...game.buyOffer.askingPlayer,
    money: game.buyOffer.askingPlayer.money - game.buyOffer.offer,
    owned: game.buyOffer.askingPlayer.owned.concat({
      ...game.buyOffer.card,
      owner: game.buyOffer.askingPlayer.id
    })
  };

  game.buyOffer.card.owner = game.buyOffer.askingPlayer.id;

  const buyerIndex = game.players.findIndex(p => p.id === game.buyOffer.askingPlayer.id);

  updatedPlayers[buyerIndex] = updatedBuyer;

  // SELL
  const updatedSeller = {
    ...game.buyOffer.currentOwner,
    money: game.buyOffer.currentOwner.money + game.buyOffer.offer,
    owned: game.buyOffer.currentOwner.owned.filter(
      (card: Card) => card.id !== game.buyOffer.card.id
    )
  };

  const sellerIndex = game.players.findIndex(p => p.id === game.buyOffer.currentOwner.id);

  updatedPlayers[sellerIndex] = updatedSeller;
  return {
    players: updatedPlayers,
    boardConfig: updatedBoardConfig
  };
};

export const addToTeam = (
  game: Game,
  upgradeCategory: string,
  previewedCard: Card,
  translation: any,
  locale: string
) => {
  const player = { ...game.players[game.activePlayerIndex] };
  const boardConfig = [...game.boardConfig];

  const activeCardIndex = game.boardConfig.findIndex(card => card.id === previewedCard.id);

  // @ts-ignore
  boardConfig[activeCardIndex].team[upgradeCategory] += 1;
  // @ts-ignore
  player.money -= previewedCard.prices[upgradeCategory].buy;
  player.actionsAvailable -= 1;

  return {
    players: [
      ...game.players.slice(0, game.activePlayerIndex),
      player,
      ...game.players.slice(game.activePlayerIndex + 1)
    ],
    boardConfig,
    log: [
      ...game.log,
      {
        date: format(new Date(), 'HH:mm'),
        name: translation('log.addedToTeam', {
          category: capitalizeAndRemoveLastLetter(
            // @ts-ignore
            game.upgradeConfig[upgradeCategory][locale === 'pl' ? 'pl' : 'en']
          ),
          card: previewedCard.title
        }),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ]
  };
};

export const promoteInTeam = (
  game: Game,
  fromUpgradeCategory: string,
  toUpgradeCategory: string,
  previewedCard: Card,
  translation: any,
  locale: string
) => {
  const player = { ...game.players[game.activePlayerIndex] };
  const boardConfig = [...game.boardConfig];

  // @ts-ignore
  boardConfig[previewedCard.id - 1].team[fromUpgradeCategory] -= 1;
  // @ts-ignore
  boardConfig[previewedCard.id - 1].team[toUpgradeCategory] += 1;
  // @ts-ignore
  player.money -= previewedCard.prices[fromUpgradeCategory].promote;
  player.actionsAvailable -= 1;

  return {
    players: [
      ...game.players.slice(0, game.activePlayerIndex),
      player,
      ...game.players.slice(game.activePlayerIndex + 1)
    ],
    log: [
      ...game.log,
      {
        date: format(new Date(), 'HH:mm'),
        name: translation('log.changedToTeam', {
          fromCategory: capitalizeAndRemoveLastLetter(
            // @ts-ignore
            game.upgradeConfig[fromUpgradeCategory][locale === 'pl' ? 'pl' : 'en']
          ),
          toCategory: capitalizeAndRemoveLastLetter(
            // @ts-ignore
            game.upgradeConfig[toUpgradeCategory][locale === 'pl' ? 'pl' : 'en']
          ),
          card: previewedCard.title
        }),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    boardConfig
  };
};

export const removeFromTeam = (
  game: Game,
  upgradeCategory: string,
  previewedCard: Card,
  translation: any,
  locale: string
) => {
  const player = { ...game.players[game.activePlayerIndex] };
  const boardConfig = [...game.boardConfig];

  // @ts-ignore
  boardConfig[previewedCard.id - 1].team[upgradeCategory] -= 1;
  // @ts-ignore
  player.money += previewedCard.prices[upgradeCategory].sell;

  return {
    players: [
      ...game.players.slice(0, game.activePlayerIndex),
      player,
      ...game.players.slice(game.activePlayerIndex + 1)
    ],
    log: [
      ...game.log,
      {
        date: format(new Date(), 'HH:mm'),
        name: translation('log.removedFromTeam', {
          category: capitalizeAndRemoveLastLetter(
            // @ts-ignore
            game.upgradeConfig[upgradeCategory][locale === 'pl' ? 'pl' : 'en']
          ),
          card: previewedCard.title
        }),
        player: {
          id: player.id,
          name: player.name,
          avatar: player.avatar
        }
      }
    ],
    boardConfig
  };
};

export const handlePayOwner = (game: Game, activeCard: Card, log: LogItem[], translation: any) => {
  const players = [...game.players];
  const activePlayer = { ...players[game.activePlayerIndex] };

  const payToIndex = game.players.findIndex(player => player.id === activeCard.owner);

  const chargedAmount = calculateCardCharge(game.boardConfig, activeCard);

  players[game.activePlayerIndex].money -= chargedAmount;
  players[payToIndex].money += chargedAmount;

  return {
    players,
    activeInsideCard: {
      id: 'payment',
      type: 'payment',
      description: translation('log.playerPays', {
        fromName: game.players[game.activePlayerIndex].name,
        toName: game.players[payToIndex].name,
        value: chargedAmount,
        card: activeCard.title
      })
    },
    log: [
      ...log,
      {
        date: format(new Date(), 'HH:mm'),
        name: translation('log.paidTo', {
          name: game.players[payToIndex].name,
          value: chargedAmount
        }),
        player: {
          id: activePlayer.id,
          name: activePlayer.name,
          avatar: activePlayer.avatar
        }
      }
    ]
  };
};
