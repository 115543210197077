export const cats: string[] = [
  'beard-happy',
  'box',
  'brown-happy',
  'dotted',
  'ears',
  'laying',
  'love',
  'mug',
  'orange',
  'white'
];

export const kittens: string[] = [
  'coffee',
  'cook',
  'crying',
  'gym',
  'music',
  'pencil',
  'pirate',
  'scream',
  'sleep',
  'what'
];

export const men: string[] = [
  'beer',
  'bicycle',
  'book',
  'football',
  'games',
  'joystick',
  'meditation',
  'paintball',
  'strong',
  'vodka',
  'wine'
];

export const alcohol: string[] = [
  'beer',
  'black-label',
  'champagne',
  'cocktail-long',
  'cocktail',
  'coconut-cocktail',
  'jack',
  'jagermeister',
  'soda',
  'vodka'
];
export const birthday: string[] = [
  'birthday-boy',
  'birthday-girl',
  'cake',
  'clown',
  'confetti',
  'disco-ball',
  'eye-mask',
  'fireworks',
  'present',
  'punch'
];
export const dinosaurs: string[] = [
  'angel',
  'birthday',
  'chef',
  'in-love',
  'music',
  'santa-claus',
  'sick',
  'sleep',
  'space',
  'thief'
];
export const dogs: string[] = [
  'beagle',
  'corgi',
  'dalmatian',
  'french-bulldog',
  'great-dane',
  'pointer',
  'poodle',
  'samoyed',
  'shiba-inu',
  'siberian-husky'
];
export const drinks: string[] = [
  'coconut',
  'coffee',
  'cola',
  'cosmopolitan',
  'cream-soda',
  'infusion',
  'latte-macchiato',
  'milk-box',
  'orange-juice',
  'wine'
];
export const fmcg: string[] = [
  'brush',
  'cream',
  'dryer',
  'eye-shadows',
  'hand-mirror',
  'lipstick',
  'nail-polish',
  'shampoo',
  'soap',
  'sun-cream'
];
export const fruit: string[] = [
  'apple',
  'avocado',
  'banana',
  'eggplant',
  'lemon',
  'onion',
  'peach',
  'pomegranate',
  'potato',
  'watermelon'
];
export const nature: string[] = [
  'avocado',
  'flower',
  'green-tea',
  'jojoba',
  'lemon',
  'mineral-water',
  'palm-oil',
  'rose',
  'tomato'
];
export const sex: string[] = [
  'anal',
  'choker',
  'dildo-rabbit',
  'dildo',
  'flogger',
  'handcuffs',
  'lingerie',
  'mask',
  'plug',
  'sex-shop'
];
export const unicorns: string[] = [
  'cat',
  'rubber-ring',
  'unicorn-2',
  'unicorn-4',
  'unicorn',
  'unicorn-5',
  'unicorn-1',
  'cake',
  'cupcake',
  'unicorn-3'
];

export const mixed: string[] = [
  'avocado',
  'cat-coffee',
  'unicorn',
  'pointer',
  'jack',
  'cola',
  'coffee',
  'watermelon',
  'box',
  'tomato',
  'apple',
  'cake',
  'sick',
  'space',
  'sun-cream',
  'onion',
  'paintball',
  'lemon',
  'mineral-water',
  'green-tea',
  'coconut-cocktail',
  'games',
  'joystick',
  'corgi',
  'samoyed',
  'shiba-inu',
  'siberian-husky',
  'birthday-boy',
  'birthday-girl',
  'sleep',
  'jagermeister',
  'potato'
];

export interface AvatarObject {
  name: string;
  label: string;
  list: string[];
}

export const defaultAvatars: AvatarObject = {
  name: 'mixed',
  label: 'Mixed',
  list: mixed
};

export const avatarList = [
  {
    name: 'mixed',
    label: {
      en: 'Mixed',
      pl: 'Różne'
    },
    list: mixed
  },
  {
    name: 'cats',
    label: {
      en: 'Cats',
      pl: 'Koty'
    },
    list: cats
  },
  {
    name: 'dogs',
    label: {
      en: 'Dogs',
      pl: 'Psy'
    },
    list: dogs
  },
  {
    name: 'dinosaurs',
    label: {
      en: 'Dinosaurs',
      pl: 'Dinozaury'
    },
    list: dinosaurs
  },
  {
    name: 'kittens',
    label: {
      en: 'Kittens',
      pl: 'Kotki'
    },
    list: kittens
  },
  {
    name: 'men',
    label: {
      en: `Men's Day`,
      pl: 'Dzień Chłopaka'
    },
    list: men
  },
  {
    name: 'unicorns',
    label: {
      en: 'Unicorns',
      pl: 'Unicorny'
    },
    list: unicorns
  },
  {
    name: 'fruit',
    label: {
      en: 'Fruit',
      pl: 'Owoce'
    },
    list: fruit
  },
  {
    name: 'nature',
    label: {
      en: 'Nature',
      pl: 'Natura'
    },
    list: nature
  },
  {
    name: 'drinks',
    label: {
      en: 'Drinks',
      pl: 'Napoje'
    },
    list: drinks
  },
  {
    name: 'alcohol',
    label: {
      en: 'Alcohol',
      pl: 'Alkohol'
    },
    list: alcohol
  },
  {
    name: 'birthday',
    label: {
      en: 'Birthday',
      pl: 'Urodziny'
    },
    list: birthday
  },
  {
    name: 'fmcg',
    label: {
      en: 'FMCG',
      pl: 'FMCG'
    },
    list: fmcg
  }
  // {
  //   name: 'sex',
  //   label: {
  //     en: 'Sex',
  //     pl: 'Seks'
  //   },
  //   list: sex
  // }
];

// <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
