import { CARD_TYPES } from '@/constants';
import { Card } from '@/interfaces/businessgame';

export const calculateCardsValue = (ownedCards: Card[]) => {
  let value = 0;

  ownedCards.forEach((card: Card) => {
    return card.team && card.price && card.prices
      ? (value +=
          Number(card.price * 0.5) +
          Number(card.team.categoryOne) * Number(card.prices.categoryOne.sell) +
          Number(card.team.categoryTwo) * Number(card.prices.categoryTwo.sell) +
          Number(card.team.categoryThree) * Number(card.prices.categoryThree.sell) +
          Number(card.team.categoryFour) * Number(card.prices.categoryFour.sell))
      : (value += (Number(card.price) || 0) * 0.5);
  });

  return Number(value);
};

export const calculateCardCharge = (boardConfig: Card[], card: Card) => {
  if (!card.prices && !card.baseCharge) {
    return 0;
  }

  let chargedAmount = 0;

  if (card.type === CARD_TYPES.TEAM && card.team && card.price && card.prices && card.baseCharge) {
    chargedAmount =
      Number(card.baseCharge) +
      Number(card.team.categoryOne) * Number(card.prices.categoryOne.charge) +
      Number(card.team.categoryTwo) * Number(card.prices.categoryTwo.charge) +
      Number(card.team.categoryThree) * Number(card.prices.categoryThree.charge) +
      Number(card.team.categoryFour) * Number(card.prices.categoryFour.charge);
  } else if (card.type === CARD_TYPES.OFFICE && card.baseCharge) {
    const officesOwned = boardConfig.filter(
      boardField =>
        // indexes of offices (train stations in Monopoly)
        [6, 16, 26, 36].includes(boardField.id) &&
        boardField.owner &&
        card.owner &&
        boardField.owner === card.owner
    );

    chargedAmount = Number(card.baseCharge) * officesOwned.length;
  } else if (card.type === CARD_TYPES.INDIVIDUAL && card.baseCharge) {
    chargedAmount = Number(card.baseCharge);
  }

  return Number(chargedAmount);
};
