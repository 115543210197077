export default {
  common: {
    newGame: 'Nowa gra',
    loading: 'Ładuję...',
    value: 'Wartość',
    close: 'Zamknij',
    yes: 'Tak',
    no: 'Nie',
    save: 'Zapisz',
    settings: 'Ustawienia',
    copiedToClipboard: 'Skopiowane do schowka',
    gameCancelled: 'Gra została anulowana i przywrócona do Twojej paczki gier',
    hide: 'Schowaj',
    options: 'Ustawienia',
    startGame: 'ZACZNIJ GRĘ',
    cancelGame: 'Anuluj grę',
    instructions: 'Instrukcje',
    waitForLink: 'Poczekaj na link do gry',
    previous: 'Wstecz',
    next: 'Dalej',
    finish: 'Zakończ',
    game: 'gra',
    games: 'gry',
    gamesMore: 'gier',
    lastRound: 'Ostatnia runda!',
    nextRound: 'Następna runda rozpoczęta',
    manualNextPhase: 'Zacznij następną rundę manualnie (jeśli coś się zepsuło)',
    areYouSure: 'Na pewno?',
    playerRemoved: 'Gracz został usunięty',
    addAdmin: 'Dodaj admina',
    adminChanged: 'Administrator dodany',
    forceNextPlayer: 'Ustaw następnego gracza',
    nextPlayerForced: 'Następny gracz ustawiony',
    players: 'Gracze',
    removePlayer: 'Usuń gracza',
    logout: 'Nastąpiło poprawne wylogowanie.',
    play: 'Zagraj',
    seconds: 'sekund',
    startNewGame: 'Rozpocznij nową grę'
  },
  games: {
    chooseGame: 'Wybierz grę',
    labels: {
      individual: 'Indywidualna',
      relationships: 'Relacje',
      twoToEight: '2 do 8 graczy',
      fourToTwenty: '4 do 20 graczy',
      fourToTen: '4 do 10 graczy',
      team: 'Zespołowa',
      rivalry: 'Rywalizacja',
      icebreaker: 'Icebreaker',
      skills: 'Rozwój'
    },
    funbusinessgame: {
      title: 'FunBusinessGame',
      description: [
        'Wybierz planszę i zagraj w grę podobną do Monopoly, ale dopasowaną do Twojej branży.',
        'Rozwijaj karty i sprawdź swoje szczęście dzięki kartom szansy.'
      ]
    },
    funteambidding: {
      title: 'FunTeamBidding',
      description: [
        'Poznaj osoby w swoim teamie i ich mocne strony. Zobacz kategorię i licytuj się z innymi zespołami, aby odpowiedzieć na pytanie.',
        'Jesteście gotowi zagrać va banque?',
        '*Pytania są pobierane z bazy na razie tylko w języku angielskim.'
      ]
    },
    funicebreaker: {
      title: 'FunIceBreaker',
      description: [
        'Krótki icebreaker, pomagający Wam lepiej się poznać.',
        'Zgaduj co inni odpowiedzieli na to samo pytanie i zdobywaj punkty.'
      ]
    },
    funteamclues: {
      description: [
        'Gra bazująca na "Tajniakach".',
        'Jedna osoba z zespołu zostaje szpiegiem i daje wskazówki reszcie drużyny. Wybierzcie słowa, które pasują do wskazówki szpiega!'
      ]
    }
  },
  forgot: {
    success: 'Powinieneś/Powinnaś dostać email z instrukcją resetowania hasła.'
  },
  board: {
    sendLinkToOthers: 'Wyślij link innym graczom',
    outside: {
      chance: 'Szansa',
      zus: 'PODATEK'
    },
    inside: {
      lastRoll: 'Ostatni rzut:',
      plays: 'gra',
      turn: 'rzuca',
      rollDices: 'Rzuć kośćmi'
    },
    gameEndedDefaulted: 'Gra zakończona! Jeden z graczy zbankrutował.',
    gameEndedTimeElapsed: 'Gra zakończona! Skończył się czas.',
    actionCard: {
      title: {
        payment: 'Płatność!',
        chance: 'Karta Szansy dla {player}!',
        tax: 'Podatek dla {player}!',
        penalty: 'Kara dla {player}!',
        bonus: 'Bonus dla {player}!'
      }
    }
  },
  players: {
    you: 'Ty',
    cash: 'Gotówka',
    payroll: 'Wypłata',
    cardsOwned: 'kart',
    admin: 'Admin'
  },
  boardCard: {
    buy: 'KUP',
    pay: 'PŁAĆ',
    sell: 'SPRZEDAJ',
    promote: 'AWANS',
    notOwned: 'Wolny',
    ifYouHave: 'Jeśli masz',
    toEmploy: 'Cena zakupu',
    letGo: 'Wartość sprzedaży',
    toPromote: 'Koszt awansu',
    costForOtherPlayers: 'Opłata dla innych graczy',
    saleValue: 'Wartość sprzedaży'
  },
  newGame: {
    name: 'Twoje imię',
    nickname: 'Ksywka',
    job: 'Stanowisko',
    strength: 'W jakiej kategorii wiedzy jesteś ekspertem?',
    questions: 'Pytania',
    weakness: 'W jakiej kategorii wiedzy nie czujesz się najlepiej?',
    startMoney: 'Gotówka na początek dla każdego gracza',
    playAudioForNextPlayer: 'Dźwięk informujący o następnej turze',
    duration: 'Długość gry w minutach. Zostaw puste jeśli chcesz grać do pierwszego bankructwa.',
    chooseAvatar: 'Wybierz avatar',
    minutes: 'Minut',
    clear: 'Wyczyść',
    startNewGame: 'Rozpocznij nową grę',
    adjustGame: 'Personalizuj',
    copyLink: 'Kopiuj link do gry',
    goToGame: 'Przejdź do gry.',
    generateGoogleMeet: 'Wygeneruj link do Google Meet',
    selectAutomatically: 'Wybierz pytania automatycznie',
    selectManually: 'Wybierz pytania ręcznie',
    numberOfQuestions: 'Liczba pytań w grze',
    categories: 'Kategorie',
    selectedQuestions: 'Wybrane pytania',
    playTheGame:
      'Czy chcesz wziąć udział w grze? Jeśli pole jest zaznaczone, weźmiesz udział w grze.',
    limitNumberOfMatching:
      'Ustaw limit na liczbę osób, które będziesz zgadywać. Wybierz tę opcję, jeśli grasz w więcej niż 8 osób.',
    firstPlayerToBeAdmin:
      'Jesteś pierwszym graczem w rozgrywce i zostajesz adminem gry. Będziesz mógł/mogła rozpocząć grę i robić dodatkowe akcje - np. usuwać graczy.',
    limitNumberOfMatchingLabel: 'Limit:',
    estimatedTime: `Jeśli nie ma wybranego limitu czasowego, będziecie grać do pierwszego bankructwa. Zwykle trwa to od 30 do 60 minut, ale może trwać dłużej, jeśli gracze mają sporą awersję do ryzyka.`,
    estimatedGameTimeIs: '* Estymowany czas gry to około:',
    for: 'Dla',
    players: 'graczy',
    moreThanEight: 'więcej niż 8 graczy',
    estimatedMinutes: 'minut'
  },
  joinGame: {
    createProfile: 'Stwórz swój profil',
    avatar: 'Wybierz awatar',
    button: 'Dołącz do gry',
    nameTaken: 'Imię zajęte przez innego gracza'
  },
  auth: {
    logIn: 'Zaloguj się',
    registerForGame: 'Zarejestruj się',
    email: 'Email',
    password: 'Hasło',
    confirmPassword: 'Powtórz hasło',
    boardTemplate: 'Szablon planszy',
    chanceTemplate: 'Szablon kart szansy',
    login: 'Zaloguj',
    register: 'Zarejestruj się',
    forgotPassword: 'Zapomniałeś hasła?',
    resetPassword: 'Resetuj hasło',
    companyName: 'Nazwa firmy',
    referralCode: 'Kod polecający',
    companyAddress: 'Adres firmy',
    taxId: 'NIP (same liczby, bez PL)',
    zipCode: 'Kod pocztowy',
    city: 'Miasto',
    country: 'Kraj'
  },
  buyOffer: {
    card: 'Karta',
    currentOwner: 'Obecny właściciel',
    askingPlayer: 'Gracz proponujący',
    offer: 'Oferta',
    wantToSell: 'Chcesz sprzedać?'
  },
  auction: {
    auction: 'Licytacja',
    bid: 'Oferta',
    yourBid: 'Twoja oferta',
    currentWinner: 'Najwyższa oferta od',
    endingIn: 'Kończy się za',
    notEnoughMoney: 'Nie masz {bid}',
    bidTooSmall: 'Licytujesz mniej niż obecna najwyższa wartość',
    nobodyWon: 'Nikt nie wygrał karty',
    playerWon: '{name} wygrywa aukcję, kupując za {value}'
  },
  endResults: 'Wyniki',
  endResultsThanks: 'Gratulacje dla wszystkich, którzy wzięli udział w integracji!',
  endResultsFeedbackPlaceholder: 'Wpisz tutaj swój feedback (opcjonalnie)',
  endResultsFeedbackHeading: 'Jak ocenisz tę grę?',
  endResultsGiveFeedback: 'Masz feedback?',
  endResultsSendFeedback: 'Wyślij',
  endResultsSendFeedbackWarning: 'Podaj ocenę lub wpisz feedback.',
  endResultsThanksForFeedback: 'Dzięki za feedback',
  funBusinessGameShortInstructions: [
    'Gra działa podobnie do Monopoly, ale plansza i karty są dopasowane do konkretnej branży, która została wybrana w ustawieniach. Gracze po kolei rzucają kośćmi, kupuje karty, rozwija je i bierze karty szansy.',
    'Kiedy staniesz na polu z kartą danego koloru, możesz ją kupić lub nie wykonywać akcji. Jeśli karta jest własnością innego gracza, musisz zapłacić.',
    'Przed każdym rzutem kośćmi możesz wykonać 1 akcję na jednej z Twoich kart. Nie musisz posiadać wszystkich kart danego koloru. Kliknij na kartę, aby ją podejrzeć i wykonaj konkretną akcję (ulepsz, sprzedaj itp.).',
    'Gdy pierwsza osoba zbankrutuje lub skończy się czas (w przypadku limitowanej gry), gra skończy się i wygrywa osoba z największym majątkiem. Majątek to gotówka + połowa ceny posiadanych kart i upgradów.',
    'Stanięcie na karcie BONUS (lewy, górny róg) daje danemu graczowi zakumulowany bonus. Wartość bonusu zwiększa się, gdy ktoś wylosuje karę w karcie szansy i po każdym przejściu przez start, przez jakiegokolwiek gracza.',
    'Udanej zabawy.'
  ],
  instructions: {
    hide: 'Schowaj',
    title: 'Instrukcje',
    previousStep: 'Poprzedni krok',
    finish: 'Zakończ',
    nextStep: 'Następny krok',
    content: [
      [
        'Ogólne',
        'Witaj w BusinessGame. Gra działa podobnie do Monopoly!',
        'Skorzystaj z kart szansy, kupuj i ulepszaj karty i wygraj.'
      ],
      [
        'Rozgrywka',
        'Każdy gracz podczas swojej tury rzuca kostkami.',
        'Po rzucie pójdziesz na kartę, na której możesz: kupić ją, wziąć szansę, odbyć karę lub zapłacić komuś.',
        'Przed każdym rzutem możesz wykonać jedną akcję, np. zatrudnić, zwolnić, awansować pracownika, a także sprzedać kartę lub spróbować odkupić kartę od innego gracza.',
        'Gdy pierwszy gracz bankrutuje, gra się kończy i wygrywa gracz z największym majątkiem.'
      ],
      [
        'Dodatkowe informacje',
        'Karta może być ulepszana jak tylko ją kupisz. Nie musisz mieć wszystkich kart z danego koloru.',
        'Każda karta pobiera 10% swojej wartości zakupu, jeśli nie jest ulepszona.',
        'Karty indywidualne nie mogą być ulepszane, ale pobierają od razu dużą sumę.',
        'Jeśli wyrzucisz tę samą wartość na obu kostkach, dostaniesz dodatkową rundę. Możesz wyrzucać te same wartości w nieskończoność (nie ma kary za 3 rzuty tych samych wartości)',
        'Kliknij na kartę, aby ją podejrzeć lub wykonać akcję.',
        'Kliknij na gracza, aby zobaczyć jego karty i wartość majątku.',
        'Stanięcie na BONUS doda do Twojego majątku jego wartość. Wartość bonusu zwiększa się z każdym przejściem przez start i gdy gracz musi zapłacić jakąś kwotę w karcie szansy.'
      ],
      ['Podsumowanie', 'Udanej zabawy!']
    ]
  },
  misc: {
    language: 'Język',
    gameLog: 'Log gry',
    possibleActions: 'Możliwe akcje',
    timeLeft: 'Pozostało',
    holidaysTheme: 'Motyw świąteczny',
    hideHolidaysTheme: 'Wyłącz motyw'
  },
  log: {
    bonusAlert: 'Obecny gracz zgarnia bonus',
    bonusDescription: 'Bonus - {value}',
    benchAlert: 'Ups. Obecny gracz płaci pół wypłaty.',
    benchDescription: 'Zapłacono pół wypłaty przez kartę na rogu',
    payTaxAlert: 'Obecny gracz płaci podatek - połowa wypłaty idzie do BONUSU.',
    payTaxDescription: 'Zapłacono podatek - {value}',
    tookChanceCard: 'Wzięto kartę szansy',
    buy: 'Karta {card} kupiona',
    sell: 'Karta {card} sprzedana',
    askToBuyHowMuch: 'Ile jesteś w stanie zapłacić {name} za kartę {card}?',
    notEnoughMoney: 'Nie masz wystarczająco kasy',
    addedToTeam: '{category} dodane do karty {card}',
    changedToTeam: '{fromCategory} zmienione na {toCategory} w karcie {card}',
    removedFromTeam: '{category} usunięte z karty {card}',
    playerPays: '{fromName} płaci {toName} {value} za {card}',
    paidTo: 'Zapłacono {value}, które dostaje {name}',
    rolled: 'Wyrzucono {diceOne} i {diceTwo}',
    payroll: 'Wypłata {value}',
    payrollAlert: 'Dostajesz payroll - {value}'
  },
  actions: {
    BUY: 'Kup',
    PASS: 'Pas',
    AUCTION: 'Zacznij aukcję',
    ASK_TO_BUY: 'Zgłoś chęć kupna',
    SELL: 'Sprzedaj',
    GET_CATEGORY_ONE: 'Kup Juniora',
    PROMOTE_CATEGORY_ONE: 'Awansuj Juniora',
    LET_GO_CATEGORY_ONE: 'Let Go Juniora',
    GET_CATEGORY_TWO: 'Kup Mida',
    PROMOTE_CATEGORY_TWO: 'Awansuj Mida',
    LET_GO_CATEGORY_TWO: 'Zwolnij Mida',
    GET_CATEGORY_THREE: 'Kup Seniora',
    PROMOTE_CATEGORY_THREE: 'Awansuj Seniora',
    LET_GO_CATEGORY_THREE: 'Zwolnij Seniora',
    GET_CATEGORY_FOUR: 'Kup Leada',
    LET_GO_CATEGORY_FOUR: 'Zwolnij Leada'
  },
  adminPanel: {
    onlyDesktop: 'Panel jest dostępny tylko na większych urządzeniach. (większe lub równe 1152px)',
    common: {
      featureNotAvailable: 'Ta akcja nie jest jeszcze zaimplementowana'
    },
    shortInstructions: [
      'W panelu głównym zobaczysz informacje o Twojej firmie i kupionej subskrypcji. W przyszłości będą tutaj zautomatyzowane akcje jak zmienianie subskrypcji i wiele innych.',
      'Twórz gry, zobacz istniejące gry, dodaj własne pytania i zmieniaj ustawienia',
      'Zakładka Info & Branding - dodaj swoje logo, zmień kolorystykę w grach i set avatarów',
      'Użytkownicy - zarządzaj użytkownikami, którzy mają dostęp do tego konta. Wyślij zaproszenia i ustal kto ma jakie role.',
      'W lewym dolnym rogu możesz zobaczyć także inne akcje, takie jak kontakt, zmiana języka i akcję wylogowania.'
    ],
    registerForm: {
      buyFirstPackage: 'Musimy założyć Ci konto, abyś mógł/mogła kupić pakiet.',
      getFreePackage: 'Zarejestruj się, aby mieć dostęp do RemoteFun',
      needInvoice: 'Potrzebuję faktury VAT',
      removeInvoice: 'Usuń dane do faktury',
      fillRequired: 'Wypełnij wszystkie potrzebne pola',
      passwordDontMatch: 'Hasła nie pasują do siebie',
      fillRequiredForInvoice: 'Wypełnij wszystkie potrzebne pola do faktury'
    },
    activeGames: {
      title: 'Aktywne / Rozegrane gry',
      noActivePackage: 'Nie masz aktywnego pakietu.',
      buyGames: 'Kup pakiet!',
      playedAllGames: 'Zagrałeś we wszystkie gry w pakiecie',
      buyMoreGames: 'Kup więcej gier',
      headers: {
        players: 'Gracze',
        createdBy: 'Utworzone przez',
        status: 'Status',
        createdOn: 'Data utworzenia',
        timeLeft: 'Pozostało czasu',
        link: 'Link'
      },
      ended: 'Zakończona',
      cancelled: 'Anulowana',
      notYetStarted: 'Nierozpoczęta',
      timeEnded: 'Czas minął',
      inProgress: 'W trakcie',
      goToGame: 'Idź do gry',
      copyLink: 'Kopiuj link do gry',
      noGamesAddedYet: 'Żadna gra nie została jeszcze rozpoczęta.',
      startFirstGame: 'Zacznij pierwszą grę'
    },
    dashboard: {
      yourPlan: 'Twój plan',
      mode: 'Tryb',
      cancel:
        'Aby anulować subskrypcję, proszę skontaktować się z supportem - {value}. Ta funkcjonalność będzie wkrótce zautomatyzowana.',
      companyId: 'ID firmy:',
      yourSubscription: 'Twoja subskrypcja:',
      gamesLeft: 'Pozostało gier:',
      boughtOn: 'Dołączono dnia:',
      packageRenewesOn: 'Subskrypcja odnawia się:',
      packageExpiresOn: 'Pakiet kończy się:',
      nextPayment: 'Następna płatność',
      unlimited: 'Nielimitowane',
      needMoreGames:
        'Potrzebujesz dodatkowych gier? Skontaktuj się z nami po specjalną ofertę. Wkrótce ta opcja będzie automatyczna.',
      users: 'Użytkownicy',
      gamesPlayed: 'Rozegrane gry',
      referralMoney: 'Środki z poleceń'
    },
    navigation: {
      support: 'Kontakt',
      language: 'Język',
      logout: 'Wyloguj',
      dashboard: 'Panel główny',
      games: 'Gry',
      listOfCards: 'Ustawienia planszy',
      cardsUpgradeConfig: 'Ustawienia rozwoju kart',
      chanceCards: 'Karty szansy',
      settings: 'Ustawienia',
      leaderboard: 'Tabela liderów',
      customQuestions: 'Własne pytania',
      questions: 'Pytania',
      companySettings: 'Ustawienia firmowe',
      infoBranding: 'Informacje i branding',
      users: 'Użytkownicy',
      getFreeCoins: 'Dostań darmowe gry!',
      help: 'Pomoc i feedback'
    },
    branding: {
      title: 'Ustawienia brandingu',
      logo: 'Logotyp',
      logoChoose: 'Wybierz logotyp (PNG, JPG',
      colors: 'Kolorystyka',
      avatars: 'Wybierz pakiet avatarów',
      brandingUpdated: 'Branding został zapisany.',
      logoUpdated: 'Logotyp został zapisany.',
      avatarsUpdated: 'Avatary zostały zapisane.',
      selected: 'wybrane',
      companyColor: 'Kolor Twojego brandu'
    },
    users: {
      title: 'Zarządzaj użytkownikami',
      inviteUser: 'Zaproś użytkownika',
      headers: {
        email: 'Email',
        status: 'Status',
        roles: 'Role',
        actions: 'Akcje'
      },
      admin: 'Administrator',
      gameCreator: 'Może dodawać gry',
      removeUser: 'Usuń użytkownika',
      copyInviteLink: 'Kopiuj link z zaproszeniem',
      copyInviteLinkTooltip:
        'Gdyby wystąpiły problemy z dostarczeniem maila, wykorzystaj tę akcję do wysłania linku manualnie.',
      invite: 'Zaproś',
      alreadyExists: 'Już istnieje w organizacji',
      inviteSent: 'Email z zaproszeniem został wysłany do { email }',
      roleUpdated: 'Rola zapisana.',
      takeAwayRights: 'Nie możesz zabrać sobie dostępów.',
      pending: 'Zaproszony',
      active: 'Aktywny'
    },
    referral: {
      title: 'Program poleceń',
      useBudget: 'Aby użyć budżetu, napisz na {value}. Ta funkcjonalność jest obecnie manualna.',
      current: 'Obecny budżet',
      receiveMoney: 'Dostań { amount } { currency } w RemoteCoinach za każde polecenie!',
      sharingCaring:
        'Jako nowy biznes staramy się zdobyć nowych użytkowników. Możesz nam w tym pomóc.',
      discountsAddUp: 'Zniżki się sumują. Im więcej firm polecissz, tym więcej środków dostaniesz',
      steps: {
        referTitle: 'POLEĆ',
        referDescription:
          'Zaproś korzystając z formularza na dole strony lub wyślij swój kod polecający.',
        getMoneyTitle: 'DOSTAŃ ŚRODKI',
        getMoneyDescription: 'Dostaniesz środki, gdy polecana firma zarejestruje się w RemoteFun.',
        buyGamesFreeTitle: 'KUPUJ PAKIETY ZA DARMO',
        buyGamesFreeDescription: 'Kupuj następne pakiety ze zniżką.'
      },
      invite: 'Zaproś inne firmy!',
      send: 'Wyślij',
      yourCode: 'Twój kod referujący',
      copyCode: 'Skopiuj kod',
      orShare: 'albo udostępnij przez',
      referralUsed: 'Twój kod został użyty!',
      copied: 'Skopiowanego do schowka.',
      inviteEmailSent: 'Email wysłany.',
      inviteEmailError:
        'Wystąpił problem z wysłaniem maila. Spróbuj ponownie lub skontaktuj się z supportem.'
    },
    help: {
      title: 'Help and Feedback',
      files: {
        title: 'Pliki do pobrania:',
        brochure: 'Ogólne informacje',
        faq: 'FAQ',
        instructions: 'Instrukcje do gier'
      },
      feedback: 'Chcesz zostawić feedback?',
      howToStart: {
        question: 'Jak zacząć używać RemoteFun?',
        answers: [
          'Na panelu po lewej stronie, wybierz konkretną grę i ją rozpocznij.',
          'Podaj swoje imię i inne informacje, a następnie wyślij link do innych graczy.',
          'Każdy gracz, łącznie z tobą, zobaczy instrukcje na początku gry.'
        ]
      },
      howToCustomizeGames: {
        question: 'Jak spersonalizować RemoteFun?',
        answers: [
          'Każda gra może być personalizowana. Kliknij na daną grę w panelu po lewej stronie, żeby zobaczyć co możesz zmieniać.',
          'w FunBusinessGame możesz zmieniać planszę, ustawienia rozwijania kart i ogólne ustawienia. W FunTeamBidding i FunIceBreaker możesz dodawać własne pytania i zmieniać ogólne ustawienia.',
          `Jeśli chcesz zmienić kolory gier i dodać swoje logo w całej aplikacji, przejdź do sekcji 'Informacje i Branding'`
        ]
      }
    },
    businessGame: {
      boardConfig: {
        headerTitle: 'Ustawienia planszy',
        selectNewTemplate: 'Wybierz nowy szablon',
        boardTemplate: 'Szablon planszy',
        apply: 'Zastosuj',
        saveAsNew: 'Zapisz jako nowy szablon',
        templateName: 'Nazwa szablonu',
        selectOtherTemplate: 'Wybierz nowy szablon',
        saveCurrentConfig: 'Zapisz obecne ustawienia jako szablon',
        previewBoard: 'Podgląd planszy',
        edit: 'Edytuj',
        preview: 'Podgląd',
        editor: 'Edytor',
        saveCard: 'Zapisz kartę',
        position: 'Pozycja',
        type: 'Typ',
        title: 'Tytuł',
        section: 'Sekcja',
        sectionColor: 'Kolor sekcji',
        price: 'Cena',
        baseCharge: 'Bazowa opłata',
        color: 'Kolor',
        canBeBought: 'Możliwe kupno',
        prices: 'Ceny',
        purchase: 'Zakup',
        sell: 'Sprzedaż',
        promote: 'Awans',
        chargeFor: 'Opłata',
        newTemplateSelected: 'Nowy szablon zapisany.',
        customTemplateAdded: 'Szablon {name} dodany.',
        boardUpdated: 'Ustawienia planszy zapisane.'
      },
      chanceCards: {
        title: 'Karty Szansy',
        addNew: 'Dodaj nową Kartę Szansy',
        editor: 'Edytor',
        saveCard: 'Zapisz kartę',
        id: 'ID',
        descriptionEn: 'Opis (Angielski)',
        descriptionPl: 'Opis (Polski)',
        descriptionEnPlaceholder: 'Wpisz tutaj opis karty szansy po angielsku',
        descriptionPlPlaceholder: 'Wpisz tutaj opis karty szansy po polsku',
        action: 'Akcja',
        config: 'Konfiguracja',
        moneyValue: 'Wartość pieniężna / w rundach',
        type: 'Typ',
        conditionValue: 'Warunek',
        from: 'Od',
        to: 'Do',
        max: 'Maksimum',
        chance: 'SZANSA',
        updated: 'Karty szansy zapisane.',
        removed: 'Karta szansy usunięta.',
        wantToProceed: 'Czy na pewno chcesz kontynuować?',
        confirmation: 'Potwierdzenie',
        actionsLabels: {
          addMoneyToCurrentPlayer: 'Dodaj pieniądze graczowi',
          addRoundToCurrentPlayer: 'Dodaj rundę graczowi',
          removeMoneyFromCurrentPlayer: 'Odejmij pieniądze graczowi',
          addToPayroll: 'Zwiększ wypłatę',
          removeFromPayroll: 'Zmniejsz wypłatę',
          removeMoneyFromCurrentPlayerIfConditions: 'Odejmij pieniądze do gracza jeśli',
          addMoneyToPayrollIfConditions: 'Zwiększ wypłatę jeśli',
          addMoneyToCurrentPlayerIfConditions: 'Dodaj pieniądze do gracza jeśli',
          changeTeamMembers: 'Zmień kategorie w karcie'
        },
        typeOptions: {
          // teamFieldMatch: 'Zespół należy do',
          // teamFieldDontMatch: 'Zespół nie należy do',
          cardsMax: 'Jeśli ma mniej lub równo X kart, to'
        },
        tooltips: {
          moneyValue: 'Kwota/liczba rund - dodana/odjęta=',
          type: 'Typ akcji, która zmienia coś w grze',
          conditionValue:
            'Maksimum czegoś, które jest zdefiniowane powyżej, żeby karta zadziałała.',
          moneyValueIfCondition: 'Money added/removed if condition passed',
          from: 'Kategoria, której będą zabrane rzeczy',
          to: 'Kategoria, której będą dodane rzeczy',
          max: 'Maksimum rzeczy, które przeniesie się między kategoriami'
        },
        newTemplateSelected: 'Nowy szablon zapisany',
        selectNewTemplate: 'Wybierz nowy szablon',
        chanceTemplate: 'Szablon Kart Szansy',
        apply: 'Zastosuje',
        saveAsNew: 'Zapisz jako nowy szablon',
        templateName: 'Nazwa szablonu',
        selectOtherTemplate: 'Wybierz inny szablon',
        saveCurrentConfig: 'Zapisz obecne karty jako nowy szablon'
      },
      cardUpgrade: {
        title: 'Ustawienia rozwoju kart',
        categoryOne: 'Kategoria nr 1',
        categoryTwo: 'Kategoria nr 2',
        categoryThree: 'Kategoria nr 3',
        categoryFour: 'Kategoria nr 4',
        english: 'Angielski',
        polish: 'Polski',
        preview: 'Podgląd',
        save: 'Zapisz',
        saved: 'Konfiguracja ulepszania kart zapisana.',
        description:
          'Niektóre karty na planszy mogą być ulepszane. Tutaj znajdziesz obecną konfigurację.'
      }
    }
  },
  checkout: {
    withRegister: {
      title: 'Koszyk',
      youAreBuying: ['Darmowe konto', 'do końca 2022 r.'],
      downloadingBoard: 'Pobieram konfigurację plansz...',
      creatingUser: 'Tworzę konto prywatne...',
      creatingCompany: 'Tworzę konto firmowe...',
      errorAddingDocument: 'Wystąpił błąd',
      redirectingToPayment: 'Przekierowuję do płatności...',
      payAndRegister: 'Zarejestruj się.',
      subscription: 'Jeden miesiąc, odnawialna subskrypcja',
      oneTime: 'Jednorazowy zakup',
      freePackage: 'Darmowy pakiet'
    },
    price: 'Cena netto:',
    priceGross: 'Cena brutto:',
    success: {
      title: 'Sukces!',
      description: 'Oto podsumowanie Twojego zakupu. Znajdziesz je także na mailu.',
      yourSubscription: 'Twoja subskrypcja:',
      boughtOn: 'Dołączono dnia:',
      renewesOn: 'Pakiet odnawi się:',
      backToDashboard: 'Idź do panelu głównego',
      referralBonus: 'Bonus z polecenia',
      mode: 'Tryb',
      invoice:
        'Faktura będzie wysłana manualnie przez nasz zespół. W przyszłości ten proces będzie zautomatyzowany.',
      toast: `Zarejestrowano pomyślnie. Możesz już używać wszystkich gier i funkcjonalności RemoteFun. Jeśli potrzebujesz pomocy, wejdź w sekcję 'Pomoc' po lewej stronie.`
    },
    fail: {
      title: 'Wystąpił błąd.',
      charged:
        'Jeśli płatność zeszła z Twojego konta, skontaktuj się z supportem {value}. Rozwiążemy ten problem tak szybko jak to możliwe.',
      notCharged:
        'Jeśli kwota nie zeszła z Twojego konta, proszę skontakuj się z nami w celu dokończenia płatności - {value}.'
    }
  },
  funDice: {
    roll: {
      turn: `{ name }'s turn"`,
      putDicesInCup: 'Put dices in the cup',
      roll: 'Roll',
      rollsLeft: '{ value } rolls left',
      fillOrCross: 'Fill in a cell or end the round by crossing out something.',
      fillOrSave:
        'Fill in a cell or save specific dices by clicking on them, so that they are not used in the next roll.'
    },
    results: {
      schoolSummary: 'School summary',
      sum: 'Sum',
      school: {
        ones: 'Ones',
        twos: 'Twos',
        threes: 'Threes',
        fours: 'Fours',
        fives: 'Fives',
        sixes: 'Sixes'
      },
      rest: {
        onePair: 'One pair',
        twoPairs: 'Two pairs',
        three: 'Three of a kind',
        four: 'Four of a kind',
        fullHouse: 'Full House',
        small: 'Small straight',
        large: 'Large Straight',
        chance: 'Chance',
        general: 'General'
      }
    }
  },
  funIceBreaker: {
    points: 'Punktów',
    currentResults: 'Tabela wyników',
    questionsLeft: 'Zostało {value} pytań',
    waitForOtherPlayers: 'Dzięki! Poczekaj na innych graczy',
    welcome: ['Witaj w FunIceBreaker!', 'Zaraz poznasz lepiej swoich współpracowników!'],
    shortInstructions: [
      'Poczekaj na innych graczy i poznajcie się.',
      'W fazie pierwszej odpowiedz na pytanie.',
      'W fazie drugiej użyj ikonki i dopasuj imiona do odpowiedzi przez przeciąganie góra-dół lub zaznacz, gdzie inni gracze skłamali.',
      `Sprawdź kto dostał najwięcej punktów i porozmawiajcie o odpowiedziach.`
    ],
    instructions: {
      guy: {
        SINGLE: {
          QUESTION:
            'Odpowiedz na pytanie. Nie ma limitu czasowego. Obok graczy zobaczysz, kto jeszcze odpowiada. Gdy odpowie ostatnia osoba, przejdziecie do następnej fazy.',
          MATCHING:
            'Uporządkuj imiona tak aby pasowały do odpowiedzi po prawej. Im szybciej to zrobisz, tym więcej punktów dostaniesz.',
          STORY:
            'Opowiedz coś o swojej odpowiedzi, tak aby Twoi współpracownicy Cię lepiej poznali.'
        },
        TRUTHLIE: {
          QUESTION:
            'Odpowiedz na pytanie. Nie ma limitu czasowego. Obok graczy zobaczysz, kto jeszcze odpowiada. Gdy odpowie ostatnia osoba, przejdziecie do następnej fazy.',
          MATCHING:
            'Przy każdej osobie zaznacz kłamstwo, które napisała i wyślij swoje odpowiedzi. Im szybciej to zrobisz, tym więcej punktów dostaniesz.',
          STORY:
            'Opowiedz coś o swojej odpowiedzi, tak aby Twoi współpracownicy Cię lepiej poznali.'
        }
      }
    },
    phases: ['Odpowiedz na pytanie', 'Dopasuj osobę z odpowiedzią', 'Opisz swoją odpowiedź'],
    phaseOf: ['Faza', 'z'],
    youAreGameCreator: 'Jesteś twórcą gry, ale zaznaczyłeś/aś, że nie bedziesz grać.',
    question: {
      truthPlaceholder: 'Wpisz tu prawdę',
      liePlaceholder: 'Wpisz tu kłamstwo',
      placeholder: 'Odpowiedz tutaj...',
      changeAnswer: 'Zmień odpowiedź',
      answer: 'Odpowiedz',
      write2Truths1Lie: 'Wpisz 2 prawdy i 1 kłamstwo.'
    },
    matching: {
      titleSingle: 'Dopasuj imiona do odpowiedzi, używając',
      titleTruthLie: 'Dla każdej osoby zaznacz odpowiedź z kłamstwem.',
      speakSingle: 'Uporządkuj imiona, tak by pasowały do odpowiedzi.',
      speakTruthLie: 'Dla każdej osoby zaznacz odpowiedź z kłamstwem.',
      waitForOtherPlayers: 'Dzięki! Poczekaj na innych graczy.',
      send: 'Wyślij odpowiedzi',
      youWillGetPoints: 'Dostaniesz {value} za każdą dobrą odpowiedź',
      similarAnswers: [
        'Kilku graczy odpowiedziało tak samo.',
        'Wszystkie pasujące odpowiedzi będą akceptowane.'
      ]
    },
    story: {
      title: ['Porozmawiajcie o swoich odpowiedziach!', '{name} może rozpoczniesz?'],
      youGetPoints: 'Dostajesz {value} punktów za ostatnią rundę.',
      startNextRound: 'Zacznij następną rundę',
      answered: 'odpowiada',
      lied: 'kłamie, pisząc',
      endGame: 'Skończ grę',
      adminStartsNextRound: 'Admin zacznie nową turę.',
      newLeader: ['Gratulacje dla {name}. Tak trzymaj!', 'Mamy nowego lidera'],
      mostPoints: [
        '{name} ma najwięcej punktów za ostatnią rundę - {points} punktów!',
        'Gratulacje!'
      ]
    },
    customQuestions: {
      type: 'Typ pytania',
      typeTooltip:
        'Wybierz czy ma to być pytanie z pojedynczą odpowiedzią czy trzema odpowiedziami (2 prawdy, 1 kłamstwo)',
      tooMany: 'Zbyt dużo wybranych pytań. Możesz wybrać między 3 a 15 pytań na grę',
      tooFew: 'Zbyt mało wybranych pytań. Możesz wybrać między 3 a 15 pytań na grę',
      types: {
        SINGLE: 'Pojedyncza odpowiedź',
        TRUTHLIE: '2 Prawdy 1 Kłamstwo'
      },
      updated: 'Pytanie zapisane.',
      wantToProceed: 'Czy na pewno usunąć pytanie?',
      confirmation: 'Jesteś pewny/pewna?',
      removed: 'Pytanie usunięte.',
      questionWrongConfig: 'Niektóre pytania nie mają tekstu pytania w przynajmniej jednym języku.'
    },
    settings: {
      noOfQuestions:
        'Wstępna liczba randomizowanych pytań - możesz ją zmienić podczas tworzenia gry lub wybrać wtedy własne pytania'
    }
  },
  funTeamBidding: {
    gameAlreadyStarted: 'Gra się już rozpoczęła. Możesz dołączyć tylko w fazie `Pytanie`',
    questionsLeft: 'Zostało {value} pytań',
    peopleStillJoining: 'Gracze, którzy dołączyli, ale są jeszcze bez zespołu.',
    goodAnswer: '{name} odpowiedzieli poprawnie! { jackpot } zostaje dodane do ich konta.',
    playedVaBanque: '{name} zagrali va banque! Będą odpowiadać po podwójną pulę.',
    teams: {
      label: 'Zespoły',
      add: 'Dodaj zespół',
      join: 'Dołącz do zespołu',
      leave: 'Opuść zespół',
      changeName: 'Zmień nazwę zespołu',
      newName: 'Nowa nazwa',
      change: 'Zmień',
      nameTaken: 'Ta nazwa jest zajęta',
      emptyName: 'Nazwa nie może być pusta'
    },
    player: {
      captain: 'Kapitan',
      makeCaptain: 'Oddaj kapitana'
    },
    shortInstructions: [
      'Dołącz do jakiegoś zespołu. Poczekaj na innych graczy i poznajcie się.',
      'Faza 1 automatycznie wybiera kategorię pytania.',
      'Faza 2 startuje zegar. Kapitanowie mogą licytować możliwość odpowiedzi na pytanie, więc decyduj razem z nim/nią. Każda nowa kwota resetuje zegar.',
      'Możesz wziąć pożyczkę lub grać va banque po podwójną pulę. Zwycięzca licytacji może odpowiedzieć na pytanie i zgarnąć pulę.'
    ],
    instructions: {
      guy: {
        WELCOME: `Dołącz do zespołu i poczekaj na innych graczy.\n\nPodzielcie się na zespoły. Więcej niż 2 zespoły zapewniają ciekawszą rozgrywkę.\n\n Puste zespoły będą usunięte, gdy gra się rozpocznie.`,
        SELECTING_CATEGORY: 'Kategoria jest wybierana automatycznie.',
        BIDDING:
          'Rozmawiaj z kapitanem i podbijajcie cenę. Za poprawną odpowiedź na pytanie z wybranej kategorii dostaniecie wartość kumulacji. Każda nowa kwota licytacji resetuje czas do {value} sekund.',
        ANSWER: 'Odpowiedz na pytanie.'
      }
    },
    phases: ['Wybór kategorii', 'Licytacja', 'Odpowiedź'],
    phaseOf: ['Faza', 'z'],
    selectedCategory: 'Wybrana kategoria',
    nextPhase: 'Następna faza za chwilę się rozpocznie.',
    bidding: {
      jackpot: 'Pula',
      history: 'Historia licytacji',
      highest: 'Zespół z najwyższą ofertą',
      timeLeft: 'Pozostało',
      seconds: 'sekund',
      captain: 'Jesteś kapitanem. Rozmawiaj z zespołem i decyduj!',
      bid: 'Licytuj',
      warning: 'Musisz wylicytować więcej niż obecna kwota lub nie masz tyle na koncie.',
      chosenCategory: 'Wybrana kategoria to {category}',
      vaBanque: 'Va Banque',
      youResigned: 'Zrezygnowaliście z aukcji',
      auctionMenu: 'Menu akcji',
      quickActions: 'Szybkie akcje',
      resign: 'Zrezygnuj',
      playVaBanque: 'Zagraj va banque',
      availableIn: 'za {value}',
      bank: 'Bank',
      loanValue: 'Wartość pożyczki',
      takeLoan: 'Weź pożyczkę',
      biddingPrice: 'Wartość licytowana',
      interest: 'Oprocentowanie (10% na rundę). Max zadłużenie {maxDebt}',
      repayValue: 'Wartość spłacana',
      repay: 'Spłać',
      wonAuction: 'Wygraliście aukcję!',
      teamWon: '{name} wygrali aukcję',
      startNextPhase: 'Zobacz pytanie',
      teamWonWillStart: 'Zespół {name} wygrał i rozpocznie następną fazę.',
      startAnswerPhase: 'Rozpocznij fazę odpowiedzi',
      resigned: 'Team {name} zrezygnował z licytacji',
      cantHaveMoreDebt: 'Nie możesz mieć więcej niż {maxDebt} długu',
      cantRepayMore: 'Nie możesz spłacić więcej niż masz długu.',
      loanSuccess: 'Wzięliście {value} pożyczki',
      repaySuccess: 'Spłaciliście {value} z długu'
    },
    answer: {
      timeLeft: 'Czas pozostały na odpowiedź:',
      answers: '- kapitan odpowiada'
    },
    categories: {
      general: 'Wiedza ogólna',
      film: 'Film',
      music: 'Muzyka',
      nature: 'Nauka i Natura',
      computers: 'Komputery',
      mathematics: 'Matematyka',
      mythology: 'Mitologia',
      sports: 'Sport',
      geography: 'Geografia',
      history: 'Historia',
      politics: 'Polityka',
      animals: 'Zwierzęta',
      custom: 'Własne'
    },
    debt: 'Dług',
    customQuestions: {
      multiple: 'Wielokrotnego wyboru',
      yesNo: 'Tak / Nie',
      updated: 'Pytanie zapisane.',
      wantToProceed: 'Czy na pewno usunąć pytanie?',
      confirmation: 'Jesteś pewny/pewna?',
      removed: 'Pytanie usunięte.',
      notValidAnswer: [
        'W pytaniach pojawiły się błędy. Dla niektórych pytań:',
        'Sprawdź błędy przed stworzeniem gry.'
      ],
      noTextInSomeQuestionText: '* Nie ma opisu pytania w przynajmniej jednym języku',
      wrongOptionsText: '* Jest za mało możliwych opcji odpowiedzi',
      noValidOptionSelectedText: '* Nie ma wybranej właściwej odpowiedzi',
      addedToCustom: 'Prawidłowo dodano jako nowy szablon.'
    },
    settings: {
      categories: 'Kategorie (Można je też dopasować podczas tworzenia gry)',
      moneyForStart: 'Pieniądze na start gry dla każdej z drużyn.',
      moneyTakenStartOfBidding: 'Pieniądze zabierane na początek każdej licytacji.',
      resetTime: 'Resetuj czas po każdej nowej kwocie w licytacji do:',
      maxDebt: 'Maksymalna kwota długu jaką zespół może mieć.'
    }
  },
  funTeamClues: {
    phasesDescriptions: [
      '{spyName} daje podpowiedź dla swojego zespołu - {activeTeamName}.',
      'Zespół {activeTeamName} zgaduje słowa na podstawie podpowiedzi.'
    ],
    shortInstructions: [
      'FunTeamClues to gra zespołowa, w której liczy się dobra współpraca. W każdym zespole musi być jeden szpieg, reszta osób to agenci.',
      'Szpiedzy widzą, które słowa na planszy należą do jakiego zespołu i które są neutralne. Podczas swojej rundy, zzpieg aktywnego zespołu daje podpowiedź reszcie zespołu i liczbę słów, które muszą zgadnąć na podstawie podpowiedzi.',
      'Zespoły odpowiadają naprzemiennie. Ten, który zgadnie wszystkie swoje słowa pierwszy, wygrywa.',
      'Na planszy jest jedno słowo, które jest tzw. "killerem" i jeśli je wybierzecie, przeciwna drużyna wygra.'
    ],
    instructions: {
      guy: {
        WELCOME: `Dołącz do zespołu. W każdym zespole musi być jeden szpieg, reszta osób to agenci.`,
        GIVE_CLUE:
          'Szpieg aktywnego zespołu daje podpowiedź reszcie zespołu i liczbę słów, które muszą zgadnąć na podstawie podpowiedzi.',
        GUESSING: `Zespół musi zgadnąć słowa, które należą do ich zespołu.`
      }
    },
    teams: {
      nameTaken: 'Ta nazwa jest zajęta'
    },
    congratsYouGuessed: 'Zostało wybrane poprawne słowo!',
    killerChosen: 'Słowo kończące grę zostało wybrane. Wygrywa Team {activeTeamName}.',
    neutralWord: 'To słowo było neutralne.',
    wrongWord: 'Zostało wybrane słowo przeciwnej drużyny.',
    teamWins: 'Drużyna {winningTeamName} wygrywa!',
    nextRound: `Następna runda, czas na Team { activeTeamName }.`,
    clue: 'Podpowiedź',
    numberToGuess: 'Liczba słów do odgadnięcia',
    answers: 'Do zgadnięcia',
    wordsLeft: 'Do zgadnięcia: ',
    operatives: 'Agenci',
    spy: 'Szpieg',
    giveClue: 'Dodaj podpowiedź',
    chooseOneSpy: 'Musicie wybrać jednego szpiega, który będzie dawał podpowiedzi.',
    noTimeLimit:
      'Dawanie podpowiedzi i zgadywanie nie mają limitu czasowego. Możecie samodzielnie mierzyć czas dostępny na odpowiedź. Ta funkcjonalność będzie dostępna w przyszłości, jeśli zajdzie taka potrzeba.',
    log: {
      gaveClue: ' dał podpowiedź-',
      guessed: ' wybrał(a)'
    },
    notAllInfoProvided: 'Wpisz podpowiedź i liczbę odpowiedzi do zgadnięcia.',
    hasToBeNumber: 'Wpisz liczbę w pole słów do zgadnięcia.',
    becomeOperative: 'Zostań agentem',
    becomeSpy: 'Zostań szpiegiem',
    gameEnded: 'Gra zakończona - Team {team} wygrał.',
    maxNineGuess: 'Możesz dać maksymalnie 9 słów do zgadnięcia.'
  },
  gamesCommon: {
    customQuestions: 'Własne pytania',
    addNewCustomQuestion: 'Dodaj nowe pytanie',
    id: 'ID',
    questionEn: 'Pytanie (Angielski)',
    questionPl: 'Pytanie (Polski)',
    type: 'Typ',
    actions: 'Akcje',
    editor: 'Edytor',
    validAnswer: 'Poprawna odpowiedź',
    questionType: 'Typ pytania',
    options: 'Opcje',
    saveQuestion: 'Zapisz pytanie',
    questionTextEn: 'Tekst pytania (Angielski)',
    questionTextPl: 'Tekst pytania (Polski)',
    validAnswerOne: 'Poprawna odpowiedź (wybierz jedno)',
    noCustomQuestions: 'Nie ma jeszcze własnych pytań',
    welcome: 'Witaj w grze',
    inviteOthers: `Zaproś innych graczy! Wyślij im link do gry.`,
    youWillStart: `Będziesz mógł/mogła rozpocząć grę, gdy wszyscy dołączą.`,
    adminWillStart: 'Administrator gry rozpocznie, gdy wszyscy dołączą.',
    startNewGameWithSelected: 'Zacznij grę z wybranymi pytaniami',
    startNewGameWithSelectedTooltip:
      'Będzie można stworzyć grę, gdy wybierzesz pytania (między 3 a 10)'
  },
  shortInstructions: 'Krótka instrukcja',
  errors: {
    somethingWentWrong: 'Coś poszło nie tak. Przeładuj stronę i spróbuj ponownie.',
    noPermissions: 'Nie masz dostępu do tej strony.',
    provideEmail: 'Poda email.',
    provideEmailPassword: 'Podaj email i hasło.',
    provideEmailPasswords: 'Podaj email i hasła.',
    noInvite: 'Nie ma zaproszenia na ten email',
    noPackageOrGames: `Nie masz pakietu lub skończyły Ci się gry {name} z pakietu.`,
    atLeastQuestions: `Przynajmniej {value} pytania są potrzebne, aby zacząć grę.`,
    noGameWithLink: 'Nie ma gry z takim linkiem'
  },
  warnings: {
    atLeastCategories: 'Musisz wybrać przynajmniej 4 kategorie.',
    noPermissionsToCreateGames:
      'Nie masz dostępu do tworzenia gier. Poproś administratora Twojej organizacji o dostęp, który można Ci nadać w panelu administratora.',
    cantBeLongerThan: 'Wartości z formularza nie mogą być dłuższe niż 25 znaków',
    tooLowMoneySuggestion:
      'Polecamy utworzenie gry z większą ilością pieniędzy dla graczy niż 2000, aby mogli ulepszać swoje karty, co urozmaici grę.',
    tooFewCustomQuestions:
      'Nie masz tyle własnych pytań. Wybrana liczba to {selected}, podczas gdy stworzonych pytań jest w bazie {created}'
  },
  success: {
    gameCreated: 'Gra została stworzona. Zaproś innych graczy i rozpocznij grę.',
    saved: 'Zapisane',
    selectedQuestionsAdded:
      'Wybrane pytania zostały dodane. Wypełnij pozostałe potrzebne informacje i zacznij grę.'
  },
  toasts: {
    success: 'Sukces',
    information: 'Informacja',
    warning: 'Uwaga',
    error: 'Błąd'
  },
  mailing: {
    hi: 'Cześć',
    from: 'z RemoteFun - Wirtualny Team Building',
    myNameIs: [
      'mam na imię {salesPerson} i jestem {salesPersonJob} RemoteFun. Niedawno ',
      '<b>wystartowaliśmy z nową platformą do wirtualnego team buildingu</b>',
      ', aby wzmocnić relacje w zespołach i zwiększyć zaangażowanie pracowników.',
      ' Myślimy, że skorzystanie z RemoteFun',
      ' przyniosłoby {clientCompany} wiele korzyści.',
      ' {personalizedSentence}'
    ],
    areYouOpen:
      'Czy moglibyśmy umówić sie na krótkie spotkanie w tym lub następnym tygodniu, aby porozmawiać o tym jakie macie wyzwania związane z pracą zdalną i jak moglibyśmy Wam pomóc?',
    scheduleMeeting: 'Tu możesz wybrać wygodny dla Ciebie termin',
    idLikeToSpeak:
      '{verb} porozmawiać z osobą z {clientCompany}, która jest odpowiedzialna za aktywności związane z team buildingiem lub kimś z zespołu HR/People.',
    ifThatsYou:
      'Jeśli to Ty - czy moglibyśmy umówić się na krótki kontakt (15 minut?) w tym lub następnym tygodniu, aby porozmawiać o tym jakie macie wyzwania związane z pracą zdalną lub prosić o kontakt z osobą odpowiedzialną za takie kwestie?',
    freePackage: `Mam <b>dla Was darmowy, testowy pakiet</b>, w którym możecie raz skorzystać z każdej aktywności. Myślę, że to świetna opcja, żeby przetestować nasze rozwiązanie. Daj znać i przygotuję dane do dostępów.`,
    animationWebsite:
      'Przesyłam też naszą animację i stronę internetową, które pokażą Ci więcej o RemoteFun.',
    answer: 'Czekam na kontakt od Ciebie!',
    cheers: 'Pozdrawiam serdecznie,',
    animation: 'Nasza animacja',
    website: 'Strona internetowa',
    scheduleCall: 'Umów się z nami na spotkanie',
    founder: 'twórcą',
    coFounder: 'współtwórczynią',
    founderCapitalized: 'Founder',
    coFounderCapitalized: 'Co-founder',
    subject: 'RemoteFun - Spersonalizuj team building w firmie - zaproszenie do współpracy'
  }
};
