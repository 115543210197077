import {
  FunTeamCluesGame,
  FunTeamCluesPlayer,
  FunTeamCluesSettings,
  FunTeamCluesTeamName
} from '@/interfaces/funteamclues';
import { unicorns, dinosaurs } from '@/constants/avatars';

export const FUN_TEAM_CLUES_PHASES: any = {
  GIVE_CLUE: {
    value: 'GIVE_CLUE',
    step: 1
  },
  GUESSING: {
    value: 'GUESSING',
    step: 2
  }
};

export const createTeam = (id: string, name: FunTeamCluesTeamName, avatarsPack: string[]) => ({
  id,
  players: [],
  name,
  avatarsPack,
  color: '',
  customName: ''
});

export const emptyFunTeamCluesSettings: FunTeamCluesSettings = {
  timeForGuessingInSeconds: null,
  timeForGivingClueInSeconds: null
};

export const emptyFunTeamCluesPlayer: FunTeamCluesPlayer = {
  isSpy: false,
  id: '',
  name: '',
  nickname: '',
  email: '',
  admin: false,
  avatar: '',
  team: '',
  avatarsPack: []
};

export const emptyFunTeamCluesGame: FunTeamCluesGame = {
  id: '',
  createdOn: 0,
  activeTeamName: '',
  lastClickedWord: null,
  clue: '',
  clueNumberOfGuesses: 0,
  settings: emptyFunTeamCluesSettings,
  gameStarted: false,
  gameEnded: false,
  gameCancelled: false,
  endResults: {},
  teams: [createTeam('1', 'RED', unicorns), createTeam('2', 'BLUE', dinosaurs)],
  players: [],
  phase: 'WELCOME',
  endOfGuessing: null,
  gameCreator: null,
  words: [],
  log: []
};
