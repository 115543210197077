import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default function () {
  const toast = useToast();
  const { t } = useI18n();

  const successToast = (description: string, life = 4000, title = t('toasts.success')) => {
    return toast.add({
      severity: 'success',
      summary: title,
      detail: description,
      life
    });
  };
  const infoToast = (description: string, life = 4000, title = t('toasts.information')) => {
    return toast.add({
      severity: 'info',
      summary: title,
      detail: description,
      life
    });
  };
  const warningToast = (description: string, life = 4000, title = t('toasts.warning')) => {
    return toast.add({
      severity: 'warn',
      summary: title,
      detail: description,
      life
    });
  };
  const errorToast = (description: string, life = 4000, title = t('toasts.error')) => {
    return toast.add({
      severity: 'error',
      summary: title,
      detail: description,
      life
    });
  };

  return { successToast, errorToast, infoToast, warningToast };
}
